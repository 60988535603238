
#calc-public-transport .calc-corp-transport-wrap{
.swiper{margin-left:auto;margin-right:auto;position:relative;overflow:hidden;list-style:none;padding:0;z-index:1}
.swiper-vertical>.swiper-wrapper{flex-direction:column}
.swiper-wrapper{position:relative;width:100%;height:100%;z-index:1;display:flex;transition-property:transform;box-sizing:content-box}
.swiper-android .swiper-slide,.swiper-wrapper{transform:translate3d(0,0,0)}.swiper-pointer-events{touch-action:pan-y}
.swiper-pointer-events.swiper-vertical{touch-action:pan-x}
.swiper-slide{flex-shrink:0;width:100%;height:100%;position:relative;transition-property:transform}
.swiper-slide-invisible-blank{visibility:hidden}
.swiper-autoheight,.swiper-autoheight .swiper-slide{height:auto}
.swiper-autoheight .swiper-wrapper{align-items:flex-start;transition-property:transform,height}
.swiper-3d,.swiper-3d.swiper-css-mode .swiper-wrapper{perspective:1200px}
.swiper-3d .swiper-cube-shadow,.swiper-3d .swiper-slide,.swiper-3d .swiper-slide-shadow,.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow-left,.swiper-3d .swiper-slide-shadow-right,.swiper-3d .swiper-slide-shadow-top,.swiper-3d .swiper-wrapper{transform-style:preserve-3d}
.swiper-3d .swiper-slide-shadow,.swiper-3d .swiper-slide-shadow-bottom,.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,.swiper-3d .swiper-slide-shadow-top{position:absolute;left:0;top:0;width:100%;height:100%;pointer-events:none;z-index:10}
.swiper-3d .swiper-slide-shadow{background:rgba(0,0,0,.15)}
.swiper-3d .swiper-slide-shadow-left{background-image:linear-gradient(to left,rgba(0,0,0,.5),rgba(0,0,0,0))}
.swiper-3d .swiper-slide-shadow-right{background-image:linear-gradient(to right,rgba(0,0,0,.5),rgba(0,0,0,0))}
.swiper-3d .swiper-slide-shadow-top{background-image:linear-gradient(to top,rgba(0,0,0,.5),rgba(0,0,0,0))}
.swiper-3d .swiper-slide-shadow-bottom{background-image:linear-gradient(to bottom,rgba(0,0,0,.5),rgba(0,0,0,0))}
.swiper-css-mode>.swiper-wrapper{overflow:auto;scrollbar-width:none;-ms-overflow-style:none}
.swiper-css-mode>.swiper-wrapper::-webkit-scrollbar{display:none}
.swiper-css-mode>.swiper-wrapper>.swiper-slide{scroll-snap-align:start start}
.swiper-horizontal.swiper-css-mode>.swiper-wrapper{scroll-snap-type:x mandatory}
.swiper-vertical.swiper-css-mode>.swiper-wrapper{scroll-snap-type:y mandatory}
.swiper-centered>.swiper-wrapper::before{content:"";flex-shrink:0;order:9999}
.swiper-centered.swiper-horizontal>.swiper-wrapper>.swiper-slide:first-child{
  -webkit-margin-start:var(--swiper-centered-offset-before);
  margin-inline-start:var(--swiper-centered-offset-before)}
.swiper-centered.swiper-horizontal>.swiper-wrapper::before{height:100%;width:var(--swiper-centered-offset-after)}
.swiper-centered.swiper-vertical>.swiper-wrapper>.swiper-slide:first-child{
  -webkit-margin-before:var(--swiper-centered-offset-before);
  margin-block-start:var(--swiper-centered-offset-before)}
.swiper-centered.swiper-vertical>.swiper-wrapper::before{width:100%;height:var(--swiper-centered-offset-after)}
.swiper-centered>.swiper-wrapper>.swiper-slide{scroll-snap-align:center center}
.not-selectable{-moz-user-select:none;-webkit-user-select:none;user-select:none}
.carousel{position:relative;box-sizing:border-box}.carousel *,.carousel :after,.carousel :before{box-sizing:inherit}
.carousel.is-draggable{cursor:move;cursor:-webkit-grab;cursor:grab}
.carousel.is-dragging{cursor:move;cursor:-webkit-grabbing;cursor:grabbing}
.carousel__viewport{position:relative;overflow:hidden;max-width:100%;max-height:100%}
.carousel__track{display:flex}
.carousel__slide{
  flex:0 0 auto;
  width:var(--carousel-slide-width,60%);
  max-width:100%;
  padding:1rem;
  position:relative;
  overflow-x:hidden;
  overflow-y:auto;
  overscroll-behavior:contain;
  -webkit-overflow-scrolling:touch;
  touch-action:pan-y}
.has-dots{margin-bottom:calc(.5rem + 22px)}
.carousel__dots{
  margin:0 auto;
  padding:0;
  position:absolute;
  top:calc(100% + .5rem);
  left:0;
  right:0;
  display:flex;
  justify-content:center;
  list-style:none;
  -webkit-user-select:none;
  -moz-user-select:none;user-select:none}
.carousel__dots .carousel__dot{margin:0;padding:0;display:block;position:relative;width:22px;height:22px;cursor:pointer}
.carousel__dots .carousel__dot:after{
  content:"";
  width:8px;
  height:8px;
  border-radius:50%;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  background-color:currentColor;
  opacity:.25;transition:opacity .15s ease-in-out}
.carousel__dots .carousel__dot.is-selected:after{opacity:1}
.carousel__button{
  width:var(--carousel-button-width,48px);
  height:var(--carousel-button-height,48px);
  padding:0;border:0;display:flex;
  justify-content:center;
  align-items:center;
  pointer-events:all;
  cursor:pointer;
  color:var(--carousel-button-color,currentColor);
  background:var(--carousel-button-bg,transparent);
  border-radius:var(--carousel-button-border-radius,50%);
  box-shadow:var(--carousel-button-shadow,none);
  transition:opacity .15s ease}.carousel__button.is-next,.carousel__button.is-prev{position:absolute;top:50%;transform:translateY(-50%)}
.carousel__button.is-prev{left:10px}
.carousel__button.is-next{right:10px}
.carousel__button[disabled]{cursor:default;opacity:.3}
.carousel__button svg{
  width:var(--carousel-button-svg-width,50%);
  height:var(--carousel-button-svg-height,50%);
  fill:none;
  stroke:currentColor;
  stroke-width:var(--carousel-button-svg-stroke-width,1.5);
  stroke-linejoin:bevel;stroke-linecap:round;
  filter:var(--carousel-button-svg-filter, none);
  pointer-events:none}
}
html.with-fancybox{scroll-behavior:auto}
body.compensate-for-scrollbar{overflow:hidden!important;touch-action:none}
.fancybox__container{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  direction:ltr;
  margin:0;
  padding:env(safe-area-inset-top,0) env(safe-area-inset-right,0) env(safe-area-inset-bottom,0) env(safe-area-inset-left,0);
  box-sizing:border-box;
  display:flex;
  flex-direction:column;
  color:var(--fancybox-color,#fff);
  -webkit-tap-highlight-color:transparent;
  overflow:hidden;
  z-index:1050;
  outline:0;
  transform-origin:top left;
  --carousel-button-width:48px;
  --carousel-button-height:48px;
  --carousel-button-svg-width:24px;
  --carousel-button-svg-height:24px;
  --carousel-button-svg-stroke-width:2.5;
  --carousel-button-svg-filter:drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4))}
.fancybox__container *,.fancybox__container ::after,.fancybox__container ::before{box-sizing:inherit}
.fancybox__container :focus{outline:0}
body:not(.is-using-mouse) .fancybox__container :focus{box-shadow:0 0 0 1px #fff,0 0 0 2px var(--fancybox-accent-color,rgba(1,210,232,.94))}
@media all and (min-width:1024px){
  .fancybox__container{--carousel-button-width:48px;--carousel-button-height:48px;--carousel-button-svg-width:27px;--carousel-button-svg-height:27px}}
.fancybox__backdrop{position:absolute;top:0;right:0;bottom:0;left:0;z-index:-1;background:var(--fancybox-bg,rgba(24,24,27,.92))}
.fancybox__carousel{position:relative;flex:1 1 auto;min-height:0;height:100%;z-index:10}
.fancybox__carousel.has-dots{margin-bottom:calc(.5rem + 22px)}
.fancybox__viewport{position:relative;width:100%;height:100%;overflow:visible;cursor:default}
.fancybox__track{display:flex;height:100%}
.fancybox__slide{
  flex:0 0 auto;
  width:100%;
  max-width:100%;
  margin:0;
  padding:48px 8px 8px 8px;
  position:relative;
  overscroll-behavior:contain;
  display:flex;
  flex-direction:column;
  outline:0;
  overflow:auto;
  -webkit-overflow-scrolling:touch;
  --carousel-button-width:36px;
  --carousel-button-height:36px;
  --carousel-button-svg-width:22px;
  --carousel-button-svg-height:22px}
.fancybox__slide::after,.fancybox__slide::before{
  content:"";flex:0 0 0;margin:auto}
@media all and (min-width:1024px){
  .fancybox__slide{padding:64px 100px}}
.fancybox__content{
  margin:0 env(safe-area-inset-right,0) 0 env(safe-area-inset-left,0);
  padding:36px;color:var(--fancybox-content-color,#374151);
  background:var(--fancybox-content-bg,#fff);
  position:relative;
  align-self:center;
  display:flex;
  flex-direction:column;
  z-index:20}
.fancybox__content :focus:not(.carousel__button.is-close){outline:thin dotted;box-shadow:none}
.fancybox__caption{
  align-self:center;
  max-width:100%;
  margin:0;
  padding:1rem 0 0 0;
  line-height:1.375;
  color:var(--fancybox-color,currentColor);
  visibility:visible;cursor:auto;flex-shrink:0;overflow-wrap:anywhere}
.is-loading .fancybox__caption{visibility:hidden}.fancybox__container>.carousel__dots{top:100%;color:var(--fancybox-color,#fff)}
.fancybox__nav .carousel__button{z-index:40}
.fancybox__nav .carousel__button.is-next{right:8px}
@media all and (min-width:1024px){.fancybox__nav .carousel__button.is-next{right:40px}}
.fancybox__nav .carousel__button.is-prev{left:8px}
@media all and (min-width:1024px){.fancybox__nav .carousel__button.is-prev{left:40px}}
.carousel__button.is-close{
  position:absolute;
  top:8px;
  right:8px;
  top:calc(env(safe-area-inset-top,0px) + 8px);
  right:calc(env(safe-area-inset-right,0px) + 8px);
  z-index:40}
@media all and (min-width:1024px){.carousel__button.is-close{right:40px}}
.fancybox__content>.carousel__button.is-close{position:absolute;top:-40px;right:0;color:var(--fancybox-color,#fff)}
.fancybox__no-click,.fancybox__no-click button{pointer-events:none}
.fancybox__spinner{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);width:50px;height:50px;color:var(--fancybox-color,currentColor)}
.fancybox__slide .fancybox__spinner{cursor:pointer;z-index:1053}
.fancybox__spinner svg{
  -webkit-animation:fancybox-rotate 2s linear infinite;
  animation:fancybox-rotate 2s linear infinite;
  transform-origin:center center;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  margin:auto;
  width:100%;
  height:100%}
.fancybox__spinner svg circle{
  fill:none;
  stroke-width:2.75;
  stroke-miterlimit:10;
  stroke-dasharray:1,200;
  stroke-dashoffset:0;
  -webkit-animation:fancybox-dash 1.5s ease-in-out infinite;
  animation:fancybox-dash 1.5s ease-in-out infinite;
  stroke-linecap:round;stroke:currentColor}
@-webkit-keyframes fancybox-rotate{100%{transform:rotate(360deg)}}
@keyframes fancybox-rotate{100%{transform:rotate(360deg)}}
@-webkit-keyframes fancybox-dash{
  0%{stroke-dasharray:1,200;stroke-dashoffset:0}
  50%{stroke-dasharray:89,200;stroke-dashoffset:-35px}
  100%{stroke-dasharray:89,200;stroke-dashoffset:-124px}}
@keyframes fancybox-dash{
  0%{stroke-dasharray:1,200;stroke-dashoffset:0}
  50%{stroke-dasharray:89,200;stroke-dashoffset:-35px}
  100%{stroke-dasharray:89,200;stroke-dashoffset:-124px}}
.carousel__button.is-close,.carousel__dots,.fancybox__backdrop,.fancybox__caption,.fancybox__nav{opacity:var(--fancybox-opacity,1)}
.fancybox__container.is-animated[aria-hidden=false] .carousel__button.is-close,
.fancybox__container.is-animated[aria-hidden=false] .carousel__dots,
.fancybox__container.is-animated[aria-hidden=false] .fancybox__backdrop,
.fancybox__container.is-animated[aria-hidden=false] .fancybox__caption,
.fancybox__container.is-animated[aria-hidden=false] .fancybox__nav{
  -webkit-animation:.15s ease backwards fancybox-fadeIn;
  animation:.15s ease backwards fancybox-fadeIn}
.fancybox__container.is-animated.is-closing .carousel__button.is-close,
.fancybox__container.is-animated.is-closing .carousel__dots,
.fancybox__container.is-animated.is-closing .fancybox__backdrop,
.fancybox__container.is-animated.is-closing .fancybox__caption,
.fancybox__container.is-animated.is-closing .fancybox__nav{-webkit-animation:.15s ease both fancybox-fadeOut;animation:.15s ease both fancybox-fadeOut}
.fancybox-fadeIn{-webkit-animation:.15s ease both fancybox-fadeIn;animation:.15s ease both fancybox-fadeIn}
.fancybox-fadeOut{-webkit-animation:.1s ease both fancybox-fadeOut;animation:.1s ease both fancybox-fadeOut}
.fancybox-zoomInUp{-webkit-animation:.2s ease both fancybox-zoomInUp;animation:.2s ease both fancybox-zoomInUp}
.fancybox-zoomOutDown{-webkit-animation:.15s ease both fancybox-zoomOutDown;animation:.15s ease both fancybox-zoomOutDown}
.fancybox-throwOutUp{-webkit-animation:.15s ease both fancybox-throwOutUp;animation:.15s ease both fancybox-throwOutUp}
.fancybox-throwOutDown{-webkit-animation:.15s ease both fancybox-throwOutDown;animation:.15s ease both fancybox-throwOutDown}
@-webkit-keyframes fancybox-fadeIn{from{opacity:0}to{opacity:1}}
@keyframes fancybox-fadeIn{from{opacity:0}to{opacity:1}}
@-webkit-keyframes fancybox-fadeOut{to{opacity:0}}
@keyframes fancybox-fadeOut{to{opacity:0}}
@-webkit-keyframes fancybox-zoomInUp{from{transform:scale(.97) translate3d(0,16px,0);opacity:0}to{transform:scale(1) translate3d(0,0,0);opacity:1}}
@keyframes fancybox-zoomInUp{from{transform:scale(.97) translate3d(0,16px,0);opacity:0}to{transform:scale(1) translate3d(0,0,0);opacity:1}}
@-webkit-keyframes fancybox-zoomOutDown{to{transform:scale(.97) translate3d(0,16px,0);opacity:0}}
@keyframes fancybox-zoomOutDown{to{transform:scale(.97) translate3d(0,16px,0);opacity:0}}
@-webkit-keyframes fancybox-throwOutUp{to{transform:translate3d(0,-30%,0);opacity:0}}
@keyframes fancybox-throwOutUp{to{transform:translate3d(0,-30%,0);opacity:0}}
@-webkit-keyframes fancybox-throwOutDown{to{transform:translate3d(0,30%,0);opacity:0}}
@keyframes fancybox-throwOutDown{to{transform:translate3d(0,30%,0);opacity:0}}
.fancybox__carousel .carousel__slide{scrollbar-width:thin;scrollbar-color:#ccc rgba(255,255,255,.1)}
.fancybox__carousel .carousel__slide::-webkit-scrollbar{width:8px;height:8px}
.fancybox__carousel .carousel__slide::-webkit-scrollbar-track{background-color:rgba(255,255,255,.1)}
.fancybox__carousel .carousel__slide::-webkit-scrollbar-thumb{background-color:#ccc;border-radius:2px;box-shadow:inset 0 0 4px rgba(0,0,0,.2)}
.fancybox__carousel.is-draggable .fancybox__slide,.fancybox__carousel.is-draggable .fancybox__slide .fancybox__content{cursor:move;cursor:-webkit-grab;cursor:grab}
.fancybox__carousel.is-dragging .fancybox__slide,.fancybox__carousel.is-dragging .fancybox__slide .fancybox__content{cursor:move;cursor:-webkit-grabbing;cursor:grabbing}
.fancybox__carousel .fancybox__slide .fancybox__content{cursor:auto}.fancybox__carousel .fancybox__slide.can-zoom_in .fancybox__content{cursor:zoom-in}
.fancybox__carousel .fancybox__slide.can-zoom_out .fancybox__content{cursor:zoom-out}
.fancybox__carousel .fancybox__slide.is-draggable .fancybox__content{cursor:move;cursor:-webkit-grab;cursor:grab}
.fancybox__carousel .fancybox__slide.is-dragging .fancybox__content{cursor:move;cursor:-webkit-grabbing;cursor:grabbing}
.fancybox__image{transform-origin:0 0;touch-action:none;-webkit-user-select:none;-moz-user-select:none;user-select:none;transition:none}
.has-image .fancybox__content{padding:0;background:0 0;min-height:1px}
.is-closing .has-image .fancybox__content{overflow:visible}.has-image[data-image-fit=contain]{overflow:visible;touch-action:none}
.has-image[data-image-fit=contain] .fancybox__content{flex-direction:row;flex-wrap:wrap}
.has-image[data-image-fit=contain] .fancybox__image{max-width:100%;max-height:100%;-o-object-fit:contain;object-fit:contain}
.has-image[data-image-fit=contain-w]{overflow-x:hidden;overflow-y:auto}
.has-image[data-image-fit=contain-w] .fancybox__content{min-height:auto}
.has-image[data-image-fit=contain-w] .fancybox__image{max-width:100%;height:auto}
.has-image[data-image-fit=cover]{overflow:visible;touch-action:none}
.has-image[data-image-fit=cover] .fancybox__content{width:100%;height:100%}
.has-image[data-image-fit=cover] .fancybox__image{width:100%;height:100%;-o-object-fit:cover;object-fit:cover}
.fancybox__carousel .fancybox__slide.has-html5video .fancybox__content,
.fancybox__carousel .fancybox__slide.has-iframe .fancybox__content,
.fancybox__carousel .fancybox__slide.has-map .fancybox__content,
.fancybox__carousel .fancybox__slide.has-pdf .fancybox__content,
.fancybox__carousel .fancybox__slide.has-video .fancybox__content{flex-shrink:1;min-height:1px;overflow:visible}
.fancybox__carousel .fancybox__slide.has-iframe .fancybox__content,
.fancybox__carousel .fancybox__slide.has-map .fancybox__content,
.fancybox__carousel .fancybox__slide.has-pdf .fancybox__content{width:100%;height:80%}
.fancybox__carousel .fancybox__slide.has-html5video .fancybox__content,
.fancybox__carousel .fancybox__slide.has-video .fancybox__content{width:960px;height:540px;max-width:100%;max-height:100%}
.fancybox__carousel .fancybox__slide.has-html5video .fancybox__content,
.fancybox__carousel .fancybox__slide.has-map .fancybox__content,
.fancybox__carousel .fancybox__slide.has-pdf .fancybox__content,
.fancybox__carousel .fancybox__slide.has-video .fancybox__content{padding:0;background:rgba(24,24,27,.9);color:#fff}
.fancybox__carousel .fancybox__slide.has-map .fancybox__content{background:#e5e3df}
.fancybox__html5video,.fancybox__iframe{border:0;display:block;height:100%;width:100%;background:0 0}
.fancybox-placeholder{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);white-space:nowrap;border-width:0}
.fancybox__thumbs{flex:0 0 auto;position:relative;padding:0 3px;opacity:var(--fancybox-opacity,1)}
.fancybox__container.is-animated[aria-hidden=false] .fancybox__thumbs{-webkit-animation:.15s ease-in backwards fancybox-fadeIn;animation:.15s ease-in backwards fancybox-fadeIn}
.fancybox__container.is-animated.is-closing .fancybox__thumbs{opacity:0}
.fancybox__thumbs .carousel__slide{
  flex:0 0 auto;width:var(--fancybox-thumbs-width,96px);
  margin:0;
  padding:8px 3px;
  box-sizing:content-box;
  display:flex;align-items:center;
  justify-content:center;overflow:visible;cursor:pointer}
.fancybox__thumbs .carousel__slide .fancybox__thumb::after{
  content:"";
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-width:5px;
  border-style:solid;
  border-color:var(--fancybox-accent-color,rgba(1,210,232,.94));
  opacity:0;
  transition:opacity .15s ease;
  border-radius:var(--fancybox-thumbs-border-radius,4px)}
.fancybox__thumbs .carousel__slide.is-nav-selected .fancybox__thumb::after{opacity:.92}
.fancybox__thumbs .carousel__slide>*{pointer-events:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}
.fancybox__thumb{
  position:relative;
  width:100%;
  padding-top:calc(100% / (var(--fancybox-thumbs-ratio,1.5)));
  background-size:cover;background-position:center center;
  background-color:rgba(255,255,255,.1);
  background-repeat:no-repeat;
  border-radius:var(--fancybox-thumbs-border-radius,4px)}
.fancybox__toolbar{
  position:absolute;
  top:0;
  right:0;
  left:0;
  z-index:20;
  background:linear-gradient(to top,rgba(0,0,0,0) 0,rgba(0,0,0,.006) 8.1%,rgba(0,0,0,.021) 15.5%,rgba(0,0,0,.046) 22.5%,rgba(0,0,0,.077) 29%,rgba(0,0,0,.114) 35.3%,rgba(0,0,0,.155) 41.2%,rgba(0,0,0,.198) 47.1%,rgba(0,0,0,.242) 52.9%,rgba(0,0,0,.285) 58.8%,rgba(0,0,0,.326) 64.7%,rgba(0,0,0,.363) 71%,rgba(0,0,0,.394) 77.5%,rgba(0,0,0,.419) 84.5%,rgba(0,0,0,.434) 91.9%,rgba(0,0,0,.44) 100%);
  padding:0;
  touch-action:none;
  display:flex;
  justify-content:space-between;
  --carousel-button-svg-width:20px;
  --carousel-button-svg-height:20px;
  /*opacity:var(--fancybox-opacity,1);*/
  text-shadow:var(--fancybox-toolbar-text-shadow,1px 1px 1px rgba(0,0,0,.4))}
@media all and (min-width:1024px){.fancybox__toolbar{padding:8px}}
.fancybox__container.is-animated[aria-hidden=false] .fancybox__toolbar{
  -webkit-animation:.15s ease-in backwards fancybox-fadeIn;
  animation:.15s ease-in backwards fancybox-fadeIn}
.fancybox__container.is-animated.is-closing .fancybox__toolbar{opacity:0}
.fancybox__toolbar__items{display:flex}.fancybox__toolbar__items--left{margin-right:auto}
.fancybox__toolbar__items--center{position:absolute;left:50%;transform:translateX(-50%)}
.fancybox__toolbar__items--right{margin-left:auto}
@media (max-width:640px){.fancybox__toolbar__items--center:not(:last-child){display:none}}
.fancybox__counter{
  min-width:72px;
  padding:0 10px;
  line-height:var(--carousel-button-height,48px);
  text-align:center;
  font-size:17px;
  font-variant-numeric:tabular-nums;
  -webkit-font-smoothing:subpixel-antialiased}
.fancybox__progress{
  /*background:var(--fancybox-accent-color,rgba(1,210,232,.94));*/
  height:3px;
  left:0;
  position:absolute;
  right:0;
  top:0;
  transform:scaleX(0);
  transform-origin:0;
  transition-property:transform;
  transition-timing-function:linear;
  z-index:30;-webkit-user-select:none;
  -moz-user-select:none;user-select:none}
.fancybox__container:-webkit-full-screen::backdrop{opacity:0}
/*.fancybox__container:fullscreen::-webkit-backdrop{opacity:0}*/
.fancybox__container:fullscreen::backdrop{opacity:0}
.fancybox__button--fullscreen g:nth-child(2){display:none}.fancybox__container:-webkit-full-screen .fancybox__button--fullscreen g:nth-child(1){display:none}
.fancybox__container:fullscreen .fancybox__button--fullscreen g:nth-child(1){display:none}
.fancybox__container:-webkit-full-screen .fancybox__button--fullscreen g:nth-child(2){display:block}
.fancybox__container:fullscreen .fancybox__button--fullscreen g:nth-child(2){display:block}
.fancybox__button--slideshow g:nth-child(2){display:none}.fancybox__container.has-slideshow .fancybox__button--slideshow g:nth-child(1){display:none}
.fancybox__container.has-slideshow .fancybox__button--slideshow g:nth-child(2){display:block}
:root{
  --bs-blue:#0d6efd;
  --bs-indigo:#6610f2;
  --bs-purple:#6f42c1;
  --bs-pink:#d63384;
  --bs-red:#dc3545;
  --bs-orange:#fd7e14;
  --bs-yellow:#ffc107;
  --bs-green:#198754;
  --bs-teal:#20c997;
  --bs-cyan:#0dcaf0;
  --bs-white:#fff;
  --bs-gray:#6c757d;
  --bs-gray-dark:#343a40;
  --bs-gray-100:#f8f9fa;
  --bs-gray-200:#e9ecef;
  --bs-gray-300:#dee2e6;
  --bs-gray-400:#ced4da;
  --bs-gray-500:#adb5bd;
  --bs-gray-600:#6c757d;
  --bs-gray-700:#495057;
  --bs-gray-800:#343a40;
  --bs-gray-900:#212529;
  --bs-primary:#0d6efd;
  --bs-secondary:#6c757d;
  --bs-success:#198754;
  --bs-info:#0dcaf0;
  --bs-warning:#ffc107;
  --bs-danger:#dc3545;
  --bs-light:#f8f9fa;
  --bs-dark:#212529;
  --bs-primary-rgb:13,110,253;
  --bs-secondary-rgb:108,117,125;
  --bs-success-rgb:25,135,84;
  --bs-info-rgb:13,202,240;
  --bs-warning-rgb:255,193,7;
  --bs-danger-rgb:220,53,69;
  --bs-light-rgb:248,249,250;
  --bs-dark-rgb:33,37,41;
  --bs-white-rgb:255,255,255;
  --bs-black-rgb:0,0,0;
  --bs-body-color-rgb:33,37,41;
  --bs-body-bg-rgb:255,255,255;
  --bs-font-sans-serif:system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient:linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family:var(--bs-font-sans-serif);
  --bs-body-font-size:1rem;
  --bs-body-font-weight:400;
  --bs-body-line-height:1.5;
  --bs-body-color:#212529;
  --bs-body-bg:#fff
}*,::after,::before{box-sizing:border-box}
@media (prefers-reduced-motion:no-preference){:root{scroll-behavior:smooth}}
body{
  margin:0;
  font-family:var(--bs-body-font-family);
  font-size:var(--bs-body-font-size);
  font-weight:var(--bs-body-font-weight);
  line-height:var(--bs-body-line-height);
  color:var(--bs-body-color);
  /*text-align:var(--bs-body-text-align);*/
  background-color:var(--bs-body-bg);
  -webkit-text-size-adjust:100%;
  -webkit-tap-highlight-color:transparent}
#calc-public-transport .calc-corp-transport-wrap {
  hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25
  }

  hr:not([size]) {
    height: 1px
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
  }

  .h1, h1 {
    font-size: calc(1.375rem + 1.5vw)
  }

  @media (min-width: 1200px) {
    .h1, h1 {
      font-size: 2.5rem
    }
  }

  .h2, h2 {
    font-size: calc(1.325rem + .9vw)
  }

  @media (min-width: 1200px) {
    .h2, h2 {
      font-size: 2rem
    }
  }

  .h3, h3 {
    font-size: calc(1.3rem + .6vw)
  }

  @media (min-width: 1200px) {
    .h3, h3 {
      font-size: 1.75rem
    }
  }

  .h4, h4 {
    font-size: calc(1.275rem + .3vw)
  }

  @media (min-width: 1200px) {
    .h4, h4 {
      font-size: 1.5rem
    }
  }

  .h5, h5 {
    font-size: 1.25rem
  }

  .h6, h6 {
    font-size: 1rem
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem
  }

  abbr[data-bs-original-title], abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
  }

  ol, ul {
    padding-left: 2rem
  }

  dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem
  }

  ol ol, ol ul, ul ol, ul ul {
    margin-bottom: 0
  }

  dt {
    font-weight: 700
  }

  dd {
    margin-bottom: .5rem;
    margin-left: 0
  }

  blockquote {
    margin: 0 0 1rem
  }

  b, strong {
    font-weight: bolder
  }

  .small, small {
    font-size: .875em
  }

  .mark, mark {
    padding: .2em;
    background-color: #fcf8e3
  }

  sub, sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
  }

  sub {
    bottom: -.25em
  }

  sup {
    top: -.5em
  }

  a {
    color: #0d6efd;
    text-decoration: underline
  }

  a:hover {
    color: #0a58ca
  }

  a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
  }

  code, kbd, pre, samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override
  }

  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em
  }

  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
  }

  code {
    font-size: .875em;
    color: #d63384;
    word-wrap: break-word
  }

  a > code {
    color: inherit
  }

  kbd {
    padding: .2rem .4rem;
    font-size: .875em;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem
  }

  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700
  }

  figure {
    margin: 0 0 1rem
  }

  img, svg {
    vertical-align: middle
  }

  table {
    caption-side: bottom;
    border-collapse: collapse
  }

  caption {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #6c757d;
    text-align: left
  }

  th {
    text-align: inherit;
    text-align: -webkit-match-parent
  }

  tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
  }

  label {
    display: inline-block
  }

  button {
    border-radius: 0
  }

  button:focus:not(:focus-visible) {
    outline: 0
  }

  button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
  }

  button, select {
    text-transform: none
  }

  [role=button] {
    cursor: pointer
  }

  select {
    word-wrap: normal
  }

  select:disabled {
    opacity: 1
  }

  [list]::-webkit-calendar-picker-indicator {
    display: none
  }

  [type=button],
  [type=reset],
  [type=submit],
  button {
    -webkit-appearance: button
  }

  [type=button]:not(:disabled),
  [type=reset]:not(:disabled),
  [type=submit]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer
  }

  ::-moz-focus-inner {
    padding: 0;
    border-style: none
  }

  textarea {
    resize: vertical
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
  }

  legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
  }

  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem
    }
  }

  legend + * {
    clear: left
  }

  /*::-webkit-datetime-edit-day-field,*/
  /*::-webkit-datetime-edit-fields-wrapper,*/
  /*::-webkit-datetime-edit-hour-field,*/
  /*::-webkit-datetime-edit-minute,*/
  /*::-webkit-datetime-edit-month-field,*/
  /*::-webkit-datetime-edit-text,*/
  /*::-webkit-datetime-edit-year-field{padding:0}*/
  ::-webkit-inner-spin-button {
    height: auto
  }

  [type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none
  }

  ::-webkit-color-swatch-wrapper {
    padding: 0
  }

  ::-webkit-file-upload-button {
    font: inherit
  }

  /*::file-selector-button{font:inherit}*/
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
  }

  output {
    display: inline-block
  }

  iframe {
    border: 0
  }

  summary {
    display: list-item;
    cursor: pointer
  }

  progress {
    vertical-align: baseline
  }

  [hidden] {
    display: none !important
  }

  .lead {
    font-size: 1.25rem;
    font-weight: 300
  }

  .display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
  }

  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem
    }
  }

  .display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
  }

  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem
    }
  }

  .display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
  }

  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem
    }
  }

  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
  }

  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem
    }
  }

  .display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
  }

  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem
    }
  }

  .display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
  }

  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem
    }
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none
  }

  .list-inline {
    padding-left: 0;
    list-style: none
  }

  .list-inline-item {
    display: inline-block
  }

  .list-inline-item:not(:last-child) {
    margin-right: .5rem
  }

  .initialism {
    font-size: .875em;
    text-transform: uppercase
  }

  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
  }

  .blockquote > :last-child {
    margin-bottom: 0
  }

  .blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #6c757d
  }

  .blockquote-footer::before {
    content: "— "
  }

  .img-fluid {
    max-width: 100%;
    height: auto
  }

  .img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto
  }

  .figure {
    display: inline-block
  }

  .figure-img {
    margin-bottom: .5rem;
    line-height: 1
  }

  .figure-caption {
    font-size: .875em;
    color: #6c757d
  }

  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto
  }

  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px
    }
  }
  @media (min-width: 768px) {
    .container, .container-md, .container-sm {
      max-width: 720px
    }
  }
  @media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 960px
    }
  }
  @media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1140px
    }
  }
  @media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1320px
    }
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
  }

  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
  }

  .col {
    flex: 1 0 0%
  }

  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-1 {
    margin-left: 8.33333333%
  }

  .offset-2 {
    margin-left: 16.66666667%
  }

  .offset-3 {
    margin-left: 25%
  }

  .offset-4 {
    margin-left: 33.33333333%
  }

  .offset-5 {
    margin-left: 41.66666667%
  }

  .offset-6 {
    margin-left: 50%
  }

  .offset-7 {
    margin-left: 58.33333333%
  }

  .offset-8 {
    margin-left: 66.66666667%
  }

  .offset-9 {
    margin-left: 75%
  }

  .offset-10 {
    margin-left: 83.33333333%
  }

  .offset-11 {
    margin-left: 91.66666667%
  }

  .g-0, .gx-0 {
    --bs-gutter-x: 0
  }

  .g-0, .gy-0 {
    --bs-gutter-y: 0
  }

  .g-1, .gx-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-1, .gy-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-2, .gx-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-2, .gy-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-3, .gx-3 {
    --bs-gutter-x: 1rem
  }

  .g-3, .gy-3 {
    --bs-gutter-y: 1rem
  }

  .g-4, .gx-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-4, .gy-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-5, .gx-5 {
    --bs-gutter-x: 3rem
  }

  .g-5, .gy-5 {
    --bs-gutter-y: 3rem
  }

  @media (min-width: 576px) {
    .col-sm {
      flex: 1 0 0%
    }
    .row-cols-sm-auto > * {
      flex: 0 0 auto;
      width: auto
    }
    .row-cols-sm-1 > * {
      flex: 0 0 auto;
      width: 100%
    }
    .row-cols-sm-2 > * {
      flex: 0 0 auto;
      width: 50%
    }
    .row-cols-sm-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%
    }
    .row-cols-sm-4 > * {
      flex: 0 0 auto;
      width: 25%
    }
    .row-cols-sm-5 > * {
      flex: 0 0 auto;
      width: 20%
    }
    .row-cols-sm-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto
    }
    .col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333333%
    }
    .col-sm-2 {
      flex: 0 0 auto;
      width: 16.66666667%
    }
    .col-sm-3 {
      flex: 0 0 auto;
      width: 25%
    }
    .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333333%
    }
    .col-sm-5 {
      flex: 0 0 auto;
      width: 41.66666667%
    }
    .col-sm-6 {
      flex: 0 0 auto;
      width: 50%
    }
    .col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333333%
    }
    .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66666667%
    }
    .col-sm-9 {
      flex: 0 0 auto;
      width: 75%
    }
    .col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333333%
    }
    .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%
    }
    .col-sm-12 {
      flex: 0 0 auto;
      width: 100%
    }
    .offset-sm-0 {
      margin-left: 0
    }
    .offset-sm-1 {
      margin-left: 8.33333333%
    }
    .offset-sm-2 {
      margin-left: 16.66666667%
    }
    .offset-sm-3 {
      margin-left: 25%
    }
    .offset-sm-4 {
      margin-left: 33.33333333%
    }
    .offset-sm-5 {
      margin-left: 41.66666667%
    }
    .offset-sm-6 {
      margin-left: 50%
    }
    .offset-sm-7 {
      margin-left: 58.33333333%
    }
    .offset-sm-8 {
      margin-left: 66.66666667%
    }
    .offset-sm-9 {
      margin-left: 75%
    }
    .offset-sm-10 {
      margin-left: 83.33333333%
    }
    .offset-sm-11 {
      margin-left: 91.66666667%
    }
    .g-sm-0, .gx-sm-0 {
      --bs-gutter-x: 0
    }
    .g-sm-0, .gy-sm-0 {
      --bs-gutter-y: 0
    }
    .g-sm-1, .gx-sm-1 {
      --bs-gutter-x: 0.25rem
    }
    .g-sm-1, .gy-sm-1 {
      --bs-gutter-y: 0.25rem
    }
    .g-sm-2, .gx-sm-2 {
      --bs-gutter-x: 0.5rem
    }
    .g-sm-2, .gy-sm-2 {
      --bs-gutter-y: 0.5rem
    }
    .g-sm-3, .gx-sm-3 {
      --bs-gutter-x: 1rem
    }
    .g-sm-3, .gy-sm-3 {
      --bs-gutter-y: 1rem
    }
    .g-sm-4, .gx-sm-4 {
      --bs-gutter-x: 1.5rem
    }
    .g-sm-4, .gy-sm-4 {
      --bs-gutter-y: 1.5rem
    }
    .g-sm-5, .gx-sm-5 {
      --bs-gutter-x: 3rem
    }
    .g-sm-5, .gy-sm-5 {
      --bs-gutter-y: 3rem
    }
  }
  @media (min-width: 768px) {
    .col-md {
      flex: 1 0 0%
    }
    .row-cols-md-auto > * {
      flex: 0 0 auto;
      width: auto
    }
    .row-cols-md-1 > * {
      flex: 0 0 auto;
      width: 100%
    }
    .row-cols-md-2 > * {
      flex: 0 0 auto;
      width: 50%
    }
    row-cols-md-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%
    }
    .row-cols-md-4 > * {
      flex: 0 0 auto;
      width: 25%
    }
    .row-cols-md-5 > * {
      flex: 0 0 auto;
      width: 20%
    }
    .row-cols-md-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto
    }
    .col-md-1 {
      flex: 0 0 auto;
      width: 8.33333333%
    }
    .col-md-2 {
      flex: 0 0 auto;
      width: 16.66666667%
    }
    .col-md-3 {
      flex: 0 0 auto;
      width: 25%
    }
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%
    }
    .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%
    }
    .col-md-6 {
      flex: 0 0 auto;
      width: 50%
    }
    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%
    }
    .col-md-8 {
      flex: 0 0 auto;
      width: 66.66666667%
    }
    .col-md-9 {
      flex: 0 0 auto;
      width: 75%
    }
    .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%
    }
    .col-md-11 {
      flex: 0 0 auto;
      width: 91.66666667%
    }
    .col-md-12 {
      flex: 0 0 auto;
      width: 100%
    }
    .offset-md-0 {
      margin-left: 0
    }
    .offset-md-1 {
      margin-left: 8.33333333%
    }
    .offset-md-2 {
      margin-left: 16.66666667%
    }
    .offset-md-3 {
      margin-left: 25%
    }
    .offset-md-4 {
      margin-left: 33.33333333%
    }
    .offset-md-5 {
      margin-left: 41.66666667%
    }
    .offset-md-6 {
      margin-left: 50%
    }
    .offset-md-7 {
      margin-left: 58.33333333%
    }
    .offset-md-8 {
      margin-left: 66.66666667%
    }
    .offset-md-9 {
      margin-left: 75%
    }
    .offset-md-10 {
      margin-left: 83.33333333%
    }
    .offset-md-11 {
      margin-left: 91.66666667%
    }
    .g-md-0, .gx-md-0 {
      --bs-gutter-x: 0
    }
    .g-md-0, .gy-md-0 {
      --bs-gutter-y: 0
    }
    .g-md-1, .gx-md-1 {
      --bs-gutter-x: 0.25rem
    }
    .g-md-1, .gy-md-1 {
      --bs-gutter-y: 0.25rem
    }
    .g-md-2, .gx-md-2 {
      --bs-gutter-x: 0.5rem
    }
    .g-md-2, .gy-md-2 {
      --bs-gutter-y: 0.5rem
    }
    .g-md-3, .gx-md-3 {
      --bs-gutter-x: 1rem
    }
    .g-md-3, .gy-md-3 {
      --bs-gutter-y: 1rem
    }
    .g-md-4, .gx-md-4 {
      --bs-gutter-x: 1.5rem
    }
    .g-md-4, .gy-md-4 {
      --bs-gutter-y: 1.5rem
    }
    .g-md-5, .gx-md-5 {
      --bs-gutter-x: 3rem
    }
    .g-md-5, .gy-md-5 {
      --bs-gutter-y: 3rem
    }
  }
  @media (min-width: 992px) {
    .col-lg {
      flex: 1 0 0%
    }
    .row-cols-lg-auto > * {
      flex: 0 0 auto;
      width: auto
    }
    .row-cols-lg-1 > * {
      flex: 0 0 auto;
      width: 100%
    }
    .row-cols-lg-2 > * {
      flex: 0 0 auto;
      width: 50%
    }
    .row-cols-lg-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%
    }
    .row-cols-lg-4 > * {
      flex: 0 0 auto;
      width: 25%
    }
    .row-cols-lg-5 > * {
      flex: 0 0 auto;
      width: 20%
    }
    .row-cols-lg-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto
    }
    .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%
    }
    .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%
    }
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%
    }
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%
    }
    .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%
    }
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%
    }
    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%
    }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%
    }
    .col-lg-9 {
      flex: 0 0 auto;
      width: 75%
    }
    .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%
    }
    .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%
    }
    .col-lg-12 {
      flex: 0 0 auto;
      width: 100%
    }
    .offset-lg-0 {
      margin-left: 0
    }
    .offset-lg-1 {
      margin-left: 8.33333333%
    }
    .offset-lg-2 {
      margin-left: 16.66666667%
    }
    .offset-lg-3 {
      margin-left: 25%
    }
    .offset-lg-4 {
      margin-left: 33.33333333%
    }
    .offset-lg-5 {
      margin-left: 41.66666667%
    }
    .offset-lg-6 {
      margin-left: 50%
    }
    .offset-lg-7 {
      margin-left: 58.33333333%
    }
    .offset-lg-8 {
      margin-left: 66.66666667%
    }
    .offset-lg-9 {
      margin-left: 75%
    }
    .offset-lg-10 {
      margin-left: 83.33333333%
    }
    .offset-lg-11 {
      margin-left: 91.66666667%
    }
    .g-lg-0, .gx-lg-0 {
      --bs-gutter-x: 0
    }
    .g-lg-0, .gy-lg-0 {
      --bs-gutter-y: 0
    }
    .g-lg-1, .gx-lg-1 {
      --bs-gutter-x: 0.25rem
    }
    .g-lg-1, .gy-lg-1 {
      --bs-gutter-y: 0.25rem
    }
    .g-lg-2, .gx-lg-2 {
      --bs-gutter-x: 0.5rem
    }
    .g-lg-2, .gy-lg-2 {
      --bs-gutter-y: 0.5rem
    }
    .g-lg-3, .gx-lg-3 {
      --bs-gutter-x: 1rem
    }
    .g-lg-3, .gy-lg-3 {
      --bs-gutter-y: 1rem
    }
    .g-lg-4, .gx-lg-4 {
      --bs-gutter-x: 1.5rem
    }
    .g-lg-4, .gy-lg-4 {
      --bs-gutter-y: 1.5rem
    }
    .g-lg-5, .gx-lg-5 {
      --bs-gutter-x: 3rem
    }
    .g-lg-5, .gy-lg-5 {
      --bs-gutter-y: 3rem
    }
  }
  @media (min-width: 1200px) {
    .col-xl {
      flex: 1 0 0%
    }
    .row-cols-xl-auto > * {
      flex: 0 0 auto;
      width: auto
    }
    .row-cols-xl-1 > * {
      flex: 0 0 auto;
      width: 100%
    }
    .row-cols-xl-2 > * {
      flex: 0 0 auto;
      width: 50%
    }
    .row-cols-xl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%
    }
    .row-cols-xl-4 > * {
      flex: 0 0 auto;
      width: 25%
    }
    .row-cols-xl-5 > * {
      flex: 0 0 auto;
      width: 20%
    }
    .row-cols-xl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto
    }
    .col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333333%
    }
    .col-xl-2 {
      flex: 0 0 auto;
      width: 16.66666667%
    }
    .col-xl-3 {
      flex: 0 0 auto;
      width: 25%
    }
    .col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333333%
    }
    .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%
    }
    .col-xl-6 {
      flex: 0 0 auto;
      width: 50%
    }
    .col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333333%
    }
    .col-xl-8 {
      flex: 0 0 auto;
      width: 66.66666667%
    }
    .col-xl-9 {
      flex: 0 0 auto;
      width: 75%
    }
    .col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333333%
    }
    .col-xl-11 {
      flex: 0 0 auto;
      width: 91.66666667%
    }
    .col-xl-12 {
      flex: 0 0 auto;
      width: 100%
    }
    .offset-xl-0 {
      margin-left: 0
    }
    .offset-xl-1 {
      margin-left: 8.33333333%
    }
    .offset-xl-2 {
      margin-left: 16.66666667%
    }
    .offset-xl-3 {
      margin-left: 25%
    }
    .offset-xl-4 {
      margin-left: 33.33333333%
    }
    .offset-xl-5 {
      margin-left: 41.66666667%
    }
    .offset-xl-6 {
      margin-left: 50%
    }
    .offset-xl-7 {
      margin-left: 58.33333333%
    }
    .offset-xl-8 {
      margin-left: 66.66666667%
    }
    .offset-xl-9 {
      margin-left: 75%
    }
    .offset-xl-10 {
      margin-left: 83.33333333%
    }
    .offset-xl-11 {
      margin-left: 91.66666667%
    }
    .g-xl-0, .gx-xl-0 {
      --bs-gutter-x: 0
    }
    .g-xl-0, .gy-xl-0 {
      --bs-gutter-y: 0
    }
    .g-xl-1, .gx-xl-1 {
      --bs-gutter-x: 0.25rem
    }
    .g-xl-1, .gy-xl-1 {
      --bs-gutter-y: 0.25rem
    }
    .g-xl-2, .gx-xl-2 {
      --bs-gutter-x: 0.5rem
    }
    .g-xl-2, .gy-xl-2 {
      --bs-gutter-y: 0.5rem
    }
    .g-xl-3, .gx-xl-3 {
      --bs-gutter-x: 1rem
    }
    .g-xl-3, .gy-xl-3 {
      --bs-gutter-y: 1rem
    }
    .g-xl-4, .gx-xl-4 {
      --bs-gutter-x: 1.5rem
    }
    .g-xl-4, .gy-xl-4 {
      --bs-gutter-y: 1.5rem
    }
    .g-xl-5, .gx-xl-5 {
      --bs-gutter-x: 3rem
    }
    .g-xl-5, .gy-xl-5 {
      --bs-gutter-y: 3rem
    }
  }
  @media (min-width: 1400px) {
    .col-xxl {
      flex: 1 0 0%
    }
    .row-cols-xxl-auto > * {
      flex: 0 0 auto;
      width: auto
    }
    .row-cols-xxl-1 > * {
      flex: 0 0 auto;
      width: 100%
    }
    row-cols-xxl-2 > * {
      flex: 0 0 auto;
      width: 50%
    }
    .row-cols-xxl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%
    }
    .row-cols-xxl-4 > * {
      flex: 0 0 auto;
      width: 25%
    }
    .row-cols-xxl-5 > * {
      flex: 0 0 auto;
      width: 20%
    }
    .row-cols-xxl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%
    }
    .col-xxl-auto {
      flex: 0 0 auto;
      width: auto
    }
    .col-xxl-1 {
      flex: 0 0 auto;
      width: 8.33333333%
    }
    .col-xxl-2 {
      flex: 0 0 auto;
      width: 16.66666667%
    }
    .col-xxl-3 {
      flex: 0 0 auto;
      width: 25%
    }
    .col-xxl-4 {
      flex: 0 0 auto;
      width: 33.33333333%
    }
    .col-xxl-5 {
      flex: 0 0 auto;
      width: 41.66666667%
    }
    .col-xxl-6 {
      flex: 0 0 auto;
      width: 50%
    }
    .col-xxl-7 {
      flex: 0 0 auto;
      width: 58.33333333%
    }
    .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.66666667%
    }
    .col-xxl-9 {
      flex: 0 0 auto;
      width: 75%
    }
    .col-xxl-10 {
      flex: 0 0 auto;
      width: 83.33333333%
    }
    .col-xxl-11 {
      flex: 0 0 auto;
      width: 91.66666667%
    }
    .col-xxl-12 {
      flex: 0 0 auto;
      width: 100%
    }
    .offset-xxl-0 {
      margin-left: 0
    }
    .offset-xxl-1 {
      margin-left: 8.33333333%
    }
    .offset-xxl-2 {
      margin-left: 16.66666667%
    }
    .offset-xxl-3 {
      margin-left: 25%
    }
    .offset-xxl-4 {
      margin-left: 33.33333333%
    }
    .offset-xxl-5 {
      margin-left: 41.66666667%
    }
    .offset-xxl-6 {
      margin-left: 50%
    }
    .offset-xxl-7 {
      margin-left: 58.33333333%
    }
    .offset-xxl-8 {
      margin-left: 66.66666667%
    }
    .offset-xxl-9 {
      margin-left: 75%
    }
    .offset-xxl-10 {
      margin-left: 83.33333333%
    }
    .offset-xxl-11 {
      margin-left: 91.66666667%
    }
    .g-xxl-0, .gx-xxl-0 {
      --bs-gutter-x: 0
    }
    .g-xxl-0, .gy-xxl-0 {
      --bs-gutter-y: 0
    }
    .g-xxl-1, .gx-xxl-1 {
      --bs-gutter-x: 0.25rem
    }
    .g-xxl-1, .gy-xxl-1 {
      --bs-gutter-y: 0.25rem
    }
    .g-xxl-2, .gx-xxl-2 {
      --bs-gutter-x: 0.5rem
    }
    .g-xxl-2, .gy-xxl-2 {
      --bs-gutter-y: 0.5rem
    }
    .g-xxl-3, .gx-xxl-3 {
      --bs-gutter-x: 1rem
    }
    .g-xxl-3, .gy-xxl-3 {
      --bs-gutter-y: 1rem
    }
    .g-xxl-4, .gx-xxl-4 {
      --bs-gutter-x: 1.5rem
    }
    .g-xxl-4, .gy-xxl-4 {
      --bs-gutter-y: 1.5rem
    }
    .g-xxl-5, .gx-xxl-5 {
      --bs-gutter-x: 3rem
    }
    .g-xxl-5, .gy-xxl-5 {
      --bs-gutter-y: 3rem
    }
  }

  .form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .125rem
  }

  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact
  }

  .form-check-input[type=checkbox] {
    border-radius: .25em
  }

  .form-check-input[type=radio] {
    border-radius: 50%
  }

  .form-check-input:active {
    filter: brightness(90%)
  }

  .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
  }

  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd
  }

  .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
  }

  .form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
  }

  .form-check-input[type=checkbox]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
  }

  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
  }

  .form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
    opacity: .5
  }

  .form-switch {
    padding-left: 2.5em
  }

  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
      transition: none
    }
  }

  .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e")
  }

  .form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
  }

  .form-check-inline {
    display: inline-block;
    margin-right: 1rem
  }

  .btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
  }

  .btn-check:disabled + .btn, .btn-check[disabled] + .btn {
    pointer-events: none;
    filter: none;
    opacity: .65
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
  }

  .input-group > .form-control, .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
  }

  .input-group > .form-control:focus, .input-group > .form-select:focus {
    z-index: 3
  }

  .input-group .btn {
    position: relative;
    z-index: 2
  }

  .input-group .btn:focus {
    z-index: 3
  }

  .input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem
  }

  .input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
  }

  .input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
  }

  .input-group-lg > .form-select, .input-group-sm > .form-select {
    padding-right: 3rem
  }

  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .fade {
    transition: opacity .15s linear
  }

  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none
    }
  }

  .fade:not(.show) {
    opacity: 0
  }

  .collapse:not(.show) {
    display: none
  }

  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
  }

  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none
    }
  }

  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width .35s ease
  }

  @media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
      transition: none
    }
  }

  .btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
  }

  .btn-group-vertical > .btn, .btn-group > .btn {
    position: relative;
    flex: 1 1 auto
  }

  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn.active,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:hover,
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn.active,
  .btn-group > .btn:active,
  .btn-group > .btn:focus,
  .btn-group > .btn:hover {
    z-index: 1
  }

  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
  }

  .btn-toolbar .input-group {
    width: auto
  }

  .btn-group > .btn-group:not(:first-child),
  .btn-group > .btn:not(:first-child) {
    margin-left: -1px
  }

  .btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
  }

  .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after {
    margin-left: 0
  }

  .dropstart .dropdown-toggle-split::before {
    margin-right: 0
  }

  .btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
  }

  .btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
  }

  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
  }

  .btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
    width: 100%
  }

  .btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
    margin-top: -1px
  }

  .btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn ~ .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .ratio {
    position: relative;
    width: 100%
  }

  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
  }

  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }

  .ratio-1x1 {
    --bs-aspect-ratio: 100%
  }

  .ratio-4x3 {
    --bs-aspect-ratio: 75%
  }

  .ratio-16x9 {
    --bs-aspect-ratio: 56.25%
  }

  .ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
  }

  .align-baseline {
    vertical-align: baseline !important
  }

  .align-top {
    vertical-align: top !important
  }

  .align-middle {
    vertical-align: middle !important
  }

  .align-bottom {
    vertical-align: bottom !important
  }

  .align-text-bottom {
    vertical-align: text-bottom !important
  }

  .align-text-top {
    vertical-align: text-top !important
  }

  .float-start {
    float: left !important
  }

  .float-end {
    float: right !important
  }

  .float-none {
    float: none !important
  }

  .opacity-0 {
    opacity: 0 !important
  }

  .opacity-25 {
    opacity: .25 !important
  }

  .opacity-50 {
    opacity: .5 !important
  }

  .opacity-75 {
    opacity: .75 !important
  }

  .opacity-100 {
    opacity: 1 !important
  }

  .overflow-auto {
    overflow: auto !important
  }

  .overflow-hidden {
    overflow: hidden !important
  }

  .overflow-visible {
    overflow: visible !important
  }

  .overflow-scroll {
    overflow: scroll !important
  }

  .d-inline {
    display: inline !important
  }

  .d-inline-block {
    display: inline-block !important
  }

  .d-block {
    display: block !important
  }

  .d-grid {
    display: grid !important
  }

  .d-table {
    display: table !important
  }

  .d-table-row {
    display: table-row !important
  }

  .d-table-cell {
    display: table-cell !important
  }

  .d-flex {
    display: flex !important
  }

  .d-inline-flex {
    display: inline-flex !important
  }

  .d-none {
    display: none !important
  }

  .shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
  }

  .shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
  }

  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
  }

  .shadow-none {
    box-shadow: none !important
  }

  .position-static {
    position: static !important
  }

  .position-relative {
    position: relative !important
  }

  .position-absolute {
    position: absolute !important
  }

  .position-fixed {
    position: fixed !important
  }

  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
  }

  .top-0 {
    top: 0 !important
  }

  .top-50 {
    top: 50% !important
  }

  .top-100 {
    top: 100% !important
  }

  .bottom-0 {
    bottom: 0 !important
  }

  .bottom-50 {
    bottom: 50% !important
  }

  .bottom-100 {
    bottom: 100% !important
  }

  .start-0 {
    left: 0 !important
  }

  .start-50 {
    left: 50% !important
  }

  .start-100 {
    left: 100% !important
  }

  .end-0 {
    right: 0 !important
  }

  .end-50 {
    right: 50% !important
  }

  .end-100 {
    right: 100% !important
  }

  .translate-middle {
    transform: translate(-50%, -50%) !important
  }

  .translate-middle-x {
    transform: translateX(-50%) !important
  }

  .translate-middle-y {
    transform: translateY(-50%) !important
  }

  .border {
    border: 1px solid #dee2e6 !important
  }

  .border-0 {
    border: 0 !important
  }

  .border-top {
    border-top: 1px solid #dee2e6 !important
  }

  .border-top-0 {
    border-top: 0 !important
  }

  .border-end {
    border-right: 1px solid #dee2e6 !important
  }

  .border-end-0 {
    border-right: 0 !important
  }

  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important
  }

  .border-bottom-0 {
    border-bottom: 0 !important
  }

  .border-start {
    border-left: 1px solid #dee2e6 !important
  }

  .border-start-0 {
    border-left: 0 !important
  }

  .border-primary {
    border-color: #0d6efd !important
  }

  .border-secondary {
    border-color: #6c757d !important
  }

  .border-success {
    border-color: #198754 !important
  }

  .border-info {
    border-color: #0dcaf0 !important
  }

  .border-warning {
    border-color: #ffc107 !important
  }

  .border-danger {
    border-color: #dc3545 !important
  }

  .border-light {
    border-color: #f8f9fa !important
  }

  .border-dark {
    border-color: #212529 !important
  }

  .border-white {
    border-color: #fff !important
  }

  .border-1 {
    border-width: 1px !important
  }

  .border-2 {
    border-width: 2px !important
  }

  .border-3 {
    border-width: 3px !important
  }

  .border-4 {
    border-width: 4px !important
  }

  .border-5 {
    border-width: 5px !important
  }

  .w-25 {
    width: 25% !important
  }

  .w-50 {
    width: 50% !important
  }

  .w-75 {
    width: 75% !important
  }

  .w-100 {
    width: 100% !important
  }

  .w-auto {
    width: auto !important
  }

  .mw-100 {
    max-width: 100% !important
  }

  .vw-100 {
    width: 100vw !important
  }

  .min-vw-100 {
    min-width: 100vw !important
  }

  .h-25 {
    height: 25% !important
  }

  .h-50 {
    height: 50% !important
  }

  .h-75 {
    height: 75% !important
  }

  h-100 {
    height: 100% !important
  }

  .h-auto {
    height: auto !important
  }

  .mh-100 {
    max-height: 100% !important
  }

  .vh-100 {
    height: 100vh !important
  }

  .min-vh-100 {
    min-height: 100vh !important
  }

  .flex-fill {
    flex: 1 1 auto !important
  }

  .flex-row {
    flex-direction: row !important
  }

  .flex-column {
    flex-direction: column !important
  }

  .flex-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-grow-0 {
    flex-grow: 0 !important
  }

  .flex-grow-1 {
    flex-grow: 1 !important
  }

  .flex-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-wrap {
    flex-wrap: wrap !important
  }

  .flex-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .gap-0 {
    gap: 0 !important
  }

  .gap-1 {
    gap: .25rem !important
  }

  .gap-2 {
    gap: .5rem !important
  }

  .gap-3 {
    gap: 1rem !important
  }

  .gap-4 {
    gap: 1.5rem !important
  }

  .gap-5 {
    gap: 3rem !important
  }

  .justify-content-start {
    justify-content: flex-start !important
  }

  .justify-content-end {
    justify-content: flex-end !important
  }

  .justify-content-center {
    justify-content: center !important
  }

  .justify-content-between {
    justify-content: space-between !important
  }

  .justify-content-around {
    justify-content: space-around !important
  }

  .justify-content-evenly {
    justify-content: space-evenly !important
  }

  .align-items-start {
    align-items: flex-start !important
  }

  .align-items-end {
    align-items: flex-end !important
  }

  .align-items-center {
    align-items: center !important
  }

  .align-items-baseline {
    align-items: baseline !important
  }

  .align-items-stretch {
    align-items: stretch !important
  }

  .align-content-start {
    align-content: flex-start !important
  }

  .align-content-end {
    align-content: flex-end !important
  }

  .align-content-center {
    align-content: center !important
  }

  .align-content-between {
    align-content: space-between !important
  }

  .align-content-around {
    align-content: space-around !important
  }

  .align-content-stretch {
    align-content: stretch !important
  }

  .align-self-auto {
    align-self: auto !important
  }

  .align-self-start {
    align-self: flex-start !important
  }

  .align-self-end {
    align-self: flex-end !important
  }

  .align-self-center {
    align-self: center !important
  }

  .align-self-baseline {
    align-self: baseline !important
  }

  .align-self-stretch {
    align-self: stretch !important
  }

  .order-first {
    order: -1 !important
  }

  .order-0 {
    order: 0 !important
  }

  .order-1 {
    order: 1 !important
  }

  .order-2 {
    order: 2 !important
  }

  .order-3 {
    order: 3 !important
  }

  .order-4 {
    order: 4 !important
  }

  .order-5 {
    order: 5 !important
  }

  .order-last {
    order: 6 !important
  }

  .m-0 {
    margin: 0 !important
  }

  .m-1 {
    margin: .25rem !important
  }

  .m-2 {
    margin: .5rem !important
  }

  .m-3 {
    margin: 1rem !important
  }

  .m-4 {
    margin: 1.5rem !important
  }

  .m-5 {
    margin: 3rem !important
  }

  .m-auto {
    margin: auto !important
  }

  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-0 {
    margin-top: 0 !important
  }

  .mt-1 {
    margin-top: .25rem !important
  }

  .mt-2 {
    margin-top: .5rem !important
  }

  .mt-3 {
    margin-top: 1rem !important
  }

  .mt-4 {
    margin-top: 1.5rem !important
  }

  .mt-5 {
    margin-top: 3rem !important
  }

  .mt-auto {
    margin-top: auto !important
  }

  .me-0 {
    margin-right: 0 !important
  }

  .me-1 {
    margin-right: .25rem !important
  }

  .me-2 {
    margin-right: .5rem !important
  }

  .me-3 {
    margin-right: 1rem !important
  }

  .me-4 {
    margin-right: 1.5rem !important
  }

  .me-5 {
    margin-right: 3rem !important
  }

  .me-auto {
    margin-right: auto !important
  }

  .mb-0 {
    margin-bottom: 0 !important
  }

  .mb-1 {
    margin-bottom: .25rem !important
  }

  .mb-2 {
    margin-bottom: .5rem !important
  }

  .mb-3 {
    margin-bottom: 1rem !important
  }

  .mb-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-5 {
    margin-bottom: 3rem !important
  }

  .mb-auto {
    margin-bottom: auto !important
  }

  .ms-0 {
    margin-left: 0 !important
  }

  .ms-1 {
    margin-left: .25rem !important
  }

  .ms-2 {
    margin-left: .5rem !important
  }

  .ms-3 {
    margin-left: 1rem !important
  }

  .ms-4 {
    margin-left: 1.5rem !important
  }

  .ms-5 {
    margin-left: 3rem !important
  }

  .ms-auto {
    margin-left: auto !important
  }

  .p-0 {
    padding: 0 !important
  }

  .p-1 {
    padding: .25rem !important
  }

  .p-2 {
    padding: .5rem !important
  }

  .p-3 {
    padding: 1rem !important
  }

  .p-4 {
    padding: 1.5rem !important
  }

  .p-5 {
    padding: 3rem !important
  }

  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-0 {
    padding-top: 0 !important
  }

  .pt-1 {
    padding-top: .25rem !important
  }

  .pt-2 {
    padding-top: .5rem !important
  }

  .pt-3 {
    padding-top: 1rem !important
  }

  .pt-4 {
    padding-top: 1.5rem !important
  }

  .pt-5 {
    padding-top: 3rem !important
  }

  .pe-0 {
    padding-right: 0 !important
  }

  .pe-1 {
    padding-right: .25rem !important
  }

  .pe-2 {
    padding-right: .5rem !important
  }

  .pe-3 {
    padding-right: 1rem !important
  }

  .pe-4 {
    padding-right: 1.5rem !important
  }

  .pe-5 {
    padding-right: 3rem !important
  }

  .pb-0 {
    padding-bottom: 0 !important
  }

  .pb-1 {
    padding-bottom: .25rem !important
  }

  .pb-2 {
    padding-bottom: .5rem !important
  }

  .pb-3 {
    padding-bottom: 1rem !important
  }

  .pb-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-5 {
    padding-bottom: 3rem !important
  }

  .ps-0 {
    padding-left: 0 !important
  }

  .ps-1 {
    padding-left: .25rem !important
  }

  .ps-2 {
    padding-left: .5rem !important
  }

  .ps-3 {
    padding-left: 1rem !important
  }

  .ps-4 {
    padding-left: 1.5rem !important
  }

  .ps-5 {
    padding-left: 3rem !important
  }

  .font-monospace {
    font-family: var(--bs-font-monospace) !important
  }

  .fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important
  }

  .fs-2 {
    font-size: calc(1.325rem + .9vw) !important
  }

  .fs-3 {
    font-size: calc(1.3rem + .6vw) !important
  }

  .fs-4 {
    font-size: calc(1.275rem + .3vw) !important
  }

  .fs-5 {
    font-size: 1.25rem !important
  }

  .fs-6 {
    font-size: 1rem !important
  }

  .fst-italic {
    font-style: italic !important
  }

  .fst-normal {
    font-style: normal !important
  }

  .fw-light {
    font-weight: 300 !important
  }

  .fw-lighter {
    font-weight: lighter !important
  }

  .fw-normal {
    font-weight: 400 !important
  }

  .fw-bold {
    font-weight: 700 !important
  }

  .fw-bolder {
    font-weight: bolder !important
  }

  .lh-1 {
    line-height: 1 !important
  }

  .lh-sm {
    line-height: 1.25 !important
  }

  .lh-base {
    line-height: 1.5 !important
  }

  .lh-lg {
    line-height: 2 !important
  }

  .text-start {
    text-align: left !important
  }

  .text-end {
    text-align: right !important
  }

  .text-center {
    text-align: center !important
  }

  .text-decoration-none {
    text-decoration: none !important
  }

  .text-decoration-underline {
    text-decoration: underline !important
  }

  .text-decoration-line-through {
    text-decoration: line-through !important
  }

  .text-lowercase {
    text-transform: lowercase !important
  }

  .text-uppercase {
    text-transform: uppercase !important
  }

  .text-capitalize {
    text-transform: capitalize !important
  }

  .text-wrap {
    white-space: normal !important
  }

  .text-nowrap {
    white-space: nowrap !important
  }

  .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
  }

  .text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
  }

  .text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
  }

  .text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
  }

  .text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
  }

  .text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
  }

  .text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
  }

  .text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
  }

  .text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
  }

  .text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
  }

  .text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
  }

  .text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
  }

  .text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important
  }

  .text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
  }

  .text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
  }

  .text-reset {
    --bs-text-opacity: 1;
    color: inherit !important
  }

  .text-opacity-25 {
    --bs-text-opacity: 0.25
  }

  .text-opacity-50 {
    --bs-text-opacity: 0.5
  }

  .text-opacity-75 {
    --bs-text-opacity: 0.75
  }

  .text-opacity-100 {
    --bs-text-opacity: 1
  }

  .bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
  }

  .bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
  }

  .bg-opacity-10 {
    --bs-bg-opacity: 0.1
  }

  .bg-opacity-25 {
    --bs-bg-opacity: 0.25
  }

  .bg-opacity-50 {
    --bs-bg-opacity: 0.5
  }

  .bg-opacity-75 {
    --bs-bg-opacity: 0.75
  }

  .bg-opacity-100 {
    --bs-bg-opacity: 1
  }

  .bg-gradient {
    background-image: var(--bs-gradient) !important
  }

  .user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
  }

  .user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important
  }

  .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important
  }

  .pe-none {
    pointer-events: none !important
  }

  .pe-auto {
    pointer-events: auto !important
  }

  .rounded {
    border-radius: .25rem !important
  }

  .rounded-0 {
    border-radius: 0 !important
  }

  .rounded-1 {
    border-radius: .2rem !important
  }

  .rounded-2 {
    border-radius: .25rem !important
  }

  .rounded-3 {
    border-radius: .3rem !important
  }

  .rounded-circle {
    border-radius: 50% !important
  }

  .rounded-pill {
    border-radius: 50rem !important
  }

  .rounded-top {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important
  }

  .rounded-end {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important
  }

  .rounded-bottom {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
  }

  .rounded-start {
    border-bottom-left-radius: .25rem !important;
    border-top-left-radius: .25rem !important
  }

  .visible {
    visibility: visible !important
  }

  .invisible {
    visibility: hidden !important
  }

  @media (min-width: 576px) {
    .float-sm-start {
      float: left !important
    }
    .float-sm-end {
      float: right !important
    }
    .float-sm-none {
      float: none !important
    }
    .d-sm-inline {
      display: inline !important
    }
    .d-sm-inline-block {
      display: inline-block !important
    }
    .d-sm-block {
      display: block !important
    }
    .d-sm-grid {
      display: grid !important
    }
    .d-sm-table {
      display: table !important
    }
    .d-sm-table-row {
      display: table-row !important
    }
    .d-sm-table-cell {
      display: table-cell !important
    }
    .d-sm-flex {
      display: flex !important
    }
    .d-sm-inline-flex {
      display: inline-flex !important
    }
    .d-sm-none {
      display: none !important
    }
    .flex-sm-fill {
      flex: 1 1 auto !important
    }
    .flex-sm-row {
      flex-direction: row !important
    }
    .flex-sm-column {
      flex-direction: column !important
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important
    }
    .flex-sm-grow-0 {
      flex-grow: 0 !important
    }
    .flex-sm-grow-1 {
      flex-grow: 1 !important
    }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important
    }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important
    }
    .gap-sm-0 {
      gap: 0 !important
    }
    .gap-sm-1 {
      gap: .25rem !important
    }
    .gap-sm-2 {
      gap: .5rem !important
    }
    .gap-sm-3 {
      gap: 1rem !important
    }
    .gap-sm-4 {
      gap: 1.5rem !important
    }
    .gap-sm-5 {
      gap: 3rem !important
    }
    .justify-content-sm-start {
      justify-content: flex-start !important
    }
    .justify-content-sm-end {
      justify-content: flex-end !important
    }
    .justify-content-sm-center {
      justify-content: center !important
    }
    .justify-content-sm-between {
      justify-content: space-between !important
    }
    .justify-content-sm-around {
      justify-content: space-around !important
    }
    .justify-content-sm-evenly {
      justify-content: space-evenly !important
    }
    .align-items-sm-start {
      align-items: flex-start !important
    }
    .align-items-sm-end {
      align-items: flex-end !important
    }
    .align-items-sm-center {
      align-items: center !important
    }
    .align-items-sm-baseline {
      align-items: baseline !important
    }
    .align-items-sm-stretch {
      align-items: stretch !important
    }
    .align-content-sm-start {
      align-content: flex-start !important
    }
    .align-content-sm-end {
      align-content: flex-end !important
    }
    .align-content-sm-center {
      align-content: center !important
    }
    .align-content-sm-between {
      align-content: space-between !important
    }
    .align-content-sm-around {
      align-content: space-around !important
    }
    .align-content-sm-stretch {
      align-content: stretch !important
    }
    .align-self-sm-auto {
      align-self: auto !important
    }
    .align-self-sm-start {
      align-self: flex-start !important
    }
    .align-self-sm-end {
      align-self: flex-end !important
    }
    .align-self-sm-center {
      align-self: center !important
    }
    .align-self-sm-baseline {
      align-self: baseline !important
    }
    .align-self-sm-stretch {
      align-self: stretch !important
    }
    .order-sm-first {
      order: -1 !important
    }
    .order-sm-0 {
      order: 0 !important
    }
    .order-sm-1 {
      order: 1 !important
    }
    .order-sm-2 {
      order: 2 !important
    }
    .order-sm-3 {
      order: 3 !important
    }
    .order-sm-4 {
      order: 4 !important
    }
    .order-sm-5 {
      order: 5 !important
    }
    .order-sm-last {
      order: 6 !important
    }
    .m-sm-0 {
      margin: 0 !important
    }
    .m-sm-1 {
      margin: .25rem !important
    }
    .m-sm-2 {
      margin: .5rem !important
    }
    .m-sm-3 {
      margin: 1rem !important
    }
    .m-sm-4 {
      margin: 1.5rem !important
    }
    .m-sm-5 {
      margin: 3rem !important
    }
    .m-sm-auto {
      margin: auto !important
    }
    .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important
    }
    .mx-sm-1 {
      margin-right: .25rem !important;
      margin-left: .25rem !important
    }
    .mx-sm-2 {
      margin-right: .5rem !important;
      margin-left: .5rem !important
    }
    .mx-sm-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important
    }
    .mx-sm-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important
    }
    .mx-sm-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important
    }
    .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important
    }
    .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important
    }
    .my-sm-1 {
      margin-top: .25rem !important;
      margin-bottom: .25rem !important
    }
    .my-sm-2 {
      margin-top: .5rem !important;
      margin-bottom: .5rem !important
    }
    .my-sm-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important
    }
    .my-sm-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important
    }
    .my-sm-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important
    }
    .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important
    }
    .mt-sm-0 {
      margin-top: 0 !important
    }
    .mt-sm-1 {
      margin-top: .25rem !important
    }
    .mt-sm-2 {
      margin-top: .5rem !important
    }
    .mt-sm-3 {
      margin-top: 1rem !important
    }
    .mt-sm-4 {
      margin-top: 1.5rem !important
    }
    .mt-sm-5 {
      margin-top: 3rem !important
    }
    .mt-sm-auto {
      margin-top: auto !important
    }
    .me-sm-0 {
      margin-right: 0 !important
    }
    .me-sm-1 {
      margin-right: .25rem !important
    }
    .me-sm-2 {
      margin-right: .5rem !important
    }
    .me-sm-3 {
      margin-right: 1rem !important
    }
    .me-sm-4 {
      margin-right: 1.5rem !important
    }
    .me-sm-5 {
      margin-right: 3rem !important
    }
    .me-sm-auto {
      margin-right: auto !important
    }
    .mb-sm-0 {
      margin-bottom: 0 !important
    }
    .mb-sm-1 {
      margin-bottom: .25rem !important
    }
    .mb-sm-2 {
      margin-bottom: .5rem !important
    }
    .mb-sm-3 {
      margin-bottom: 1rem !important
    }
    .mb-sm-4 {
      margin-bottom: 1.5rem !important
    }
    .mb-sm-5 {
      margin-bottom: 3rem !important
    }
    .mb-sm-auto {
      margin-bottom: auto !important
    }
    .ms-sm-0 {
      margin-left: 0 !important
    }
    .ms-sm-1 {
      margin-left: .25rem !important
    }
    .ms-sm-2 {
      margin-left: .5rem !important
    }
    .ms-sm-3 {
      margin-left: 1rem !important
    }
    .ms-sm-4 {
      margin-left: 1.5rem !important
    }
    .ms-sm-5 {
      margin-left: 3rem !important
    }
    .ms-sm-auto {
      margin-left: auto !important
    }
    .p-sm-0 {
      padding: 0 !important
    }
    .p-sm-1 {
      padding: .25rem !important
    }
    .p-sm-2 {
      padding: .5rem !important
    }
    .p-sm-3 {
      padding: 1rem !important
    }
    .p-sm-4 {
      padding: 1.5rem !important
    }
    .p-sm-5 {
      padding: 3rem !important
    }
    .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important
    }
    .px-sm-1 {
      padding-right: .25rem !important;
      padding-left: .25rem !important
    }
    .px-sm-2 {
      padding-right: .5rem !important;
      padding-left: .5rem !important
    }
    .px-sm-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important
    }
    .px-sm-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important
    }
    .px-sm-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important
    }
    .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important
    }
    .py-sm-1 {
      padding-top: .25rem !important;
      padding-bottom: .25rem !important
    }
    .py-sm-2 {
      padding-top: .5rem !important;
      padding-bottom: .5rem !important
    }
    .py-sm-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important
    }
    .py-sm-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important
    }
    .py-sm-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important
    }
    .pt-sm-0 {
      padding-top: 0 !important
    }
    .pt-sm-1 {
      padding-top: .25rem !important
    }
    .pt-sm-2 {
      padding-top: .5rem !important
    }
    .pt-sm-3 {
      padding-top: 1rem !important
    }
    .pt-sm-4 {
      padding-top: 1.5rem !important
    }
    .pt-sm-5 {
      padding-top: 3rem !important
    }
    .pe-sm-0 {
      padding-right: 0 !important
    }
    .pe-sm-1 {
      padding-right: .25rem !important
    }
    .pe-sm-2 {
      padding-right: .5rem !important
    }
    .pe-sm-3 {
      padding-right: 1rem !important
    }
    .pe-sm-4 {
      padding-right: 1.5rem !important
    }
    .pe-sm-5 {
      padding-right: 3rem !important
    }
    .pb-sm-0 {
      padding-bottom: 0 !important
    }
    .pb-sm-1 {
      padding-bottom: .25rem !important
    }
    .pb-sm-2 {
      padding-bottom: .5rem !important
    }
    .pb-sm-3 {
      padding-bottom: 1rem !important
    }
    .pb-sm-4 {
      padding-bottom: 1.5rem !important
    }
    .pb-sm-5 {
      padding-bottom: 3rem !important
    }
    .ps-sm-0 {
      padding-left: 0 !important
    }
    .ps-sm-1 {
      padding-left: .25rem !important
    }
    .ps-sm-2 {
      padding-left: .5rem !important
    }
    .ps-sm-3 {
      padding-left: 1rem !important
    }
    .ps-sm-4 {
      padding-left: 1.5rem !important
    }
    .ps-sm-5 {
      padding-left: 3rem !important
    }
    .text-sm-start {
      text-align: left !important
    }
    .text-sm-end {
      text-align: right !important
    }
    .text-sm-center {
      text-align: center !important
    }
  }
  @media (min-width: 768px) {
    .float-md-start {
      float: left !important
    }
    .float-md-end {
      float: right !important
    }
    .float-md-none {
      float: none !important
    }
    .d-md-inline {
      display: inline !important
    }
    .d-md-inline-block {
      display: inline-block !important
    }
    .d-md-block {
      display: block !important
    }
    .d-md-grid {
      display: grid !important
    }
    .d-md-table {
      display: table !important
    }
    .d-md-table-row {
      display: table-row !important
    }
    .d-md-table-cell {
      display: table-cell !important
    }
    .d-md-flex {
      display: flex !important
    }
    .d-md-inline-flex {
      display: inline-flex !important
    }
    .d-md-none {
      display: none !important
    }
    .flex-md-fill {
      flex: 1 1 auto !important
    }
    .flex-md-row {
      flex-direction: row !important
    }
    .flex-md-column {
      flex-direction: column !important
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important
    }
    .flex-md-grow-0 {
      flex-grow: 0 !important
    }
    .flex-md-grow-1 {
      flex-grow: 1 !important
    }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important
    }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important
    }
    .flex-md-wrap {
      flex-wrap: wrap !important
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important
    }
    .gap-md-0 {
      gap: 0 !important
    }
    .gap-md-1 {
      gap: .25rem !important
    }
    .gap-md-2 {
      gap: .5rem !important
    }
    .gap-md-3 {
      gap: 1rem !important
    }
    .gap-md-4 {
      gap: 1.5rem !important
    }
    .gap-md-5 {
      gap: 3rem !important
    }
    .justify-content-md-start {
      justify-content: flex-start !important
    }
    .justify-content-md-end {
      justify-content: flex-end !important
    }
    .justify-content-md-center {
      justify-content: center !important
    }
    .justify-content-md-between {
      justify-content: space-between !important
    }
    .justify-content-md-around {
      justify-content: space-around !important
    }
    .justify-content-md-evenly {
      justify-content: space-evenly !important
    }
    .align-items-md-start {
      align-items: flex-start !important
    }
    .align-items-md-end {
      align-items: flex-end !important
    }
    .align-items-md-center {
      align-items: center !important
    }
    .align-items-md-baseline {
      align-items: baseline !important
    }
    .align-items-md-stretch {
      align-items: stretch !important
    }
    .align-content-md-start {
      align-content: flex-start !important
    }
    .align-content-md-end {
      align-content: flex-end !important
    }
    .align-content-md-center {
      align-content: center !important
    }
    .align-content-md-between {
      align-content: space-between !important
    }
    .align-content-md-around {
      align-content: space-around !important
    }
    .align-content-md-stretch {
      align-content: stretch !important
    }
    .align-self-md-auto {
      align-self: auto !important
    }
    .align-self-md-start {
      align-self: flex-start !important
    }
    .align-self-md-end {
      align-self: flex-end !important
    }
    .align-self-md-center {
      align-self: center !important
    }
    .align-self-md-baseline {
      align-self: baseline !important
    }
    .align-self-md-stretch {
      align-self: stretch !important
    }
    .order-md-first {
      order: -1 !important
    }
    .order-md-0 {
      order: 0 !important
    }
    .order-md-1 {
      order: 1 !important
    }
    .order-md-2 {
      order: 2 !important
    }
    .order-md-3 {
      order: 3 !important
    }
    .order-md-4 {
      order: 4 !important
    }
    .order-md-5 {
      order: 5 !important
    }
    .order-md-last {
      order: 6 !important
    }
    .m-md-0 {
      margin: 0 !important
    }
    .m-md-1 {
      margin: .25rem !important
    }
    .m-md-2 {
      margin: .5rem !important
    }
    .m-md-3 {
      margin: 1rem !important
    }
    .m-md-4 {
      margin: 1.5rem !important
    }
    .m-md-5 {
      margin: 3rem !important
    }
    .m-md-auto {
      margin: auto !important
    }
    .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important
    }
    .mx-md-1 {
      margin-right: .25rem !important;
      margin-left: .25rem !important
    }
    .mx-md-2 {
      margin-right: .5rem !important;
      margin-left: .5rem !important
    }
    .mx-md-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important
    }
    .mx-md-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important
    }
    .mx-md-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important
    }
    .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important
    }
    .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important
    }
    .my-md-1 {
      margin-top: .25rem !important;
      margin-bottom: .25rem !important
    }
    .my-md-2 {
      margin-top: .5rem !important;
      margin-bottom: .5rem !important
    }
    .my-md-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important
    }
    .my-md-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important
    }
    .my-md-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important
    }
    .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important
    }
    .mt-md-0 {
      margin-top: 0 !important
    }
    .mt-md-1 {
      margin-top: .25rem !important
    }
    .mt-md-2 {
      margin-top: .5rem !important
    }
    .mt-md-3 {
      margin-top: 1rem !important
    }
    .mt-md-4 {
      margin-top: 1.5rem !important
    }
    .mt-md-5 {
      margin-top: 3rem !important
    }
    .mt-md-auto {
      margin-top: auto !important
    }
    .me-md-0 {
      margin-right: 0 !important
    }
    .me-md-1 {
      margin-right: .25rem !important
    }
    .me-md-2 {
      margin-right: .5rem !important
    }
    .me-md-3 {
      margin-right: 1rem !important
    }
    .me-md-4 {
      margin-right: 1.5rem !important
    }
    .me-md-5 {
      margin-right: 3rem !important
    }
    .me-md-auto {
      margin-right: auto !important
    }
    .mb-md-0 {
      margin-bottom: 0 !important
    }
    .mb-md-1 {
      margin-bottom: .25rem !important
    }
    .mb-md-2 {
      margin-bottom: .5rem !important
    }
    .mb-md-3 {
      margin-bottom: 1rem !important
    }
    .mb-md-4 {
      margin-bottom: 1.5rem !important
    }
    .mb-md-5 {
      margin-bottom: 3rem !important
    }
    .mb-md-auto {
      margin-bottom: auto !important
    }
    .ms-md-0 {
      margin-left: 0 !important
    }
    .ms-md-1 {
      margin-left: .25rem !important
    }
    .ms-md-2 {
      margin-left: .5rem !important
    }
    .ms-md-3 {
      margin-left: 1rem !important
    }
    .ms-md-4 {
      margin-left: 1.5rem !important
    }
    .ms-md-5 {
      margin-left: 3rem !important
    }
    .ms-md-auto {
      margin-left: auto !important
    }
    .p-md-0 {
      padding: 0 !important
    }
    .p-md-1 {
      padding: .25rem !important
    }
    .p-md-2 {
      padding: .5rem !important
    }
    .p-md-3 {
      padding: 1rem !important
    }
    .p-md-4 {
      padding: 1.5rem !important
    }
    .p-md-5 {
      padding: 3rem !important
    }
    .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important
    }
    .px-md-1 {
      padding-right: .25rem !important;
      padding-left: .25rem !important
    }
    .px-md-2 {
      padding-right: .5rem !important;
      padding-left: .5rem !important
    }
    .px-md-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important
    }
    .px-md-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important
    }
    .px-md-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important
    }
    .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important
    }
    .py-md-1 {
      padding-top: .25rem !important;
      padding-bottom: .25rem !important
    }
    .py-md-2 {
      padding-top: .5rem !important;
      padding-bottom: .5rem !important
    }
    .py-md-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important
    }
    .py-md-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important
    }
    .py-md-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important
    }
    .pt-md-0 {
      padding-top: 0 !important
    }
    .pt-md-1 {
      padding-top: .25rem !important
    }
    .pt-md-2 {
      padding-top: .5rem !important
    }
    .pt-md-3 {
      padding-top: 1rem !important
    }
    .pt-md-4 {
      padding-top: 1.5rem !important
    }
    .pt-md-5 {
      padding-top: 3rem !important
    }
    .pe-md-0 {
      padding-right: 0 !important
    }
    .pe-md-1 {
      padding-right: .25rem !important
    }
    .pe-md-2 {
      padding-right: .5rem !important
    }
    .pe-md-3 {
      padding-right: 1rem !important
    }
    .pe-md-4 {
      padding-right: 1.5rem !important
    }
    .pe-md-5 {
      padding-right: 3rem !important
    }
    .pb-md-0 {
      padding-bottom: 0 !important
    }
    .pb-md-1 {
      padding-bottom: .25rem !important
    }
    .pb-md-2 {
      padding-bottom: .5rem !important
    }
    .pb-md-3 {
      padding-bottom: 1rem !important
    }
    .pb-md-4 {
      padding-bottom: 1.5rem !important
    }
    .pb-md-5 {
      padding-bottom: 3rem !important
    }
    .ps-md-0 {
      padding-left: 0 !important
    }
    .ps-md-1 {
      padding-left: .25rem !important
    }
    .ps-md-2 {
      padding-left: .5rem !important
    }
    .ps-md-3 {
      padding-left: 1rem !important
    }
    .ps-md-4 {
      padding-left: 1.5rem !important
    }
    .ps-md-5 {
      padding-left: 3rem !important
    }
    .text-md-start {
      text-align: left !important
    }
    .text-md-end {
      text-align: right !important
    }
    .text-md-center {
      text-align: center !important
    }
  }
  @media (min-width: 992px) {
    .float-lg-start {
      float: left !important
    }
    .float-lg-end {
      float: right !important
    }
    .float-lg-none {
      float: none !important
    }
    .d-lg-inline {
      display: inline !important
    }
    .d-lg-inline-block {
      display: inline-block !important
    }
    .d-lg-block {
      display: block !important
    }
    .d-lg-grid {
      display: grid !important
    }
    .d-lg-table {
      display: table !important
    }
    .d-lg-table-row {
      display: table-row !important
    }
    .d-lg-table-cell {
      display: table-cell !important
    }
    .d-lg-flex {
      display: flex !important
    }
    .d-lg-inline-flex {
      display: inline-flex !important
    }
    .d-lg-none {
      display: none !important
    }
    .flex-lg-fill {
      flex: 1 1 auto !important
    }
    .flex-lg-row {
      flex-direction: row !important
    }
    .flex-lg-column {
      flex-direction: column !important
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important
    }
    .flex-lg-grow-0 {
      flex-grow: 0 !important
    }
    .flex-lg-grow-1 {
      flex-grow: 1 !important
    }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important
    }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important
    }
    .gap-lg-0 {
      gap: 0 !important
    }
    .gap-lg-1 {
      gap: .25rem !important
    }
    .gap-lg-2 {
      gap: .5rem !important
    }
    .gap-lg-3 {
      gap: 1rem !important
    }
    .gap-lg-4 {
      gap: 1.5rem !important
    }
    .gap-lg-5 {
      gap: 3rem !important
    }
    .justify-content-lg-start {
      justify-content: flex-start !important
    }
    .justify-content-lg-end {
      justify-content: flex-end !important
    }
    .justify-content-lg-center {
      justify-content: center !important
    }
    .justify-content-lg-between {
      justify-content: space-between !important
    }
    .justify-content-lg-around {
      justify-content: space-around !important
    }
    .justify-content-lg-evenly {
      justify-content: space-evenly !important
    }
    .align-items-lg-start {
      align-items: flex-start !important
    }
    .align-items-lg-end {
      align-items: flex-end !important
    }
    .align-items-lg-center {
      align-items: center !important
    }
    .align-items-lg-baseline {
      align-items: baseline !important
    }
    .align-items-lg-stretch {
      align-items: stretch !important
    }
    .align-content-lg-start {
      align-content: flex-start !important
    }
    .align-content-lg-end {
      align-content: flex-end !important
    }
    .align-content-lg-center {
      align-content: center !important
    }
    .align-content-lg-between {
      align-content: space-between !important
    }
    .align-content-lg-around {
      align-content: space-around !important
    }
    .align-content-lg-stretch {
      align-content: stretch !important
    }
    .align-self-lg-auto {
      align-self: auto !important
    }
    .align-self-lg-start {
      align-self: flex-start !important
    }
    .align-self-lg-end {
      align-self: flex-end !important
    }
    .align-self-lg-center {
      align-self: center !important
    }
    .align-self-lg-baseline {
      align-self: baseline !important
    }
    .align-self-lg-stretch {
      align-self: stretch !important
    }
    .order-lg-first {
      order: -1 !important
    }
    .order-lg-0 {
      order: 0 !important
    }
    .order-lg-1 {
      order: 1 !important
    }
    .order-lg-2 {
      order: 2 !important
    }
    .order-lg-3 {
      order: 3 !important
    }
    .order-lg-4 {
      order: 4 !important
    }
    .order-lg-5 {
      order: 5 !important
    }
    .order-lg-last {
      order: 6 !important
    }
    .m-lg-0 {
      margin: 0 !important
    }
    .m-lg-1 {
      margin: .25rem !important
    }
    .m-lg-2 {
      margin: .5rem !important
    }
    .m-lg-3 {
      margin: 1rem !important
    }
    .m-lg-4 {
      margin: 1.5rem !important
    }
    .m-lg-5 {
      margin: 3rem !important
    }
    .m-lg-auto {
      margin: auto !important
    }
    .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important
    }
    .mx-lg-1 {
      margin-right: .25rem !important;
      margin-left: .25rem !important
    }
    .mx-lg-2 {
      margin-right: .5rem !important;
      margin-left: .5rem !important
    }
    .mx-lg-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important
    }
    .mx-lg-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important
    }
    .mx-lg-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important
    }
    .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important
    }
    .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important
    }
    .my-lg-1 {
      margin-top: .25rem !important;
      margin-bottom: .25rem !important
    }
    .my-lg-2 {
      margin-top: .5rem !important;
      margin-bottom: .5rem !important
    }
    .my-lg-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important
    }
    .my-lg-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important
    }
    .my-lg-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important
    }
    .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important
    }
    .mt-lg-0 {
      margin-top: 0 !important
    }
    .mt-lg-1 {
      margin-top: .25rem !important
    }
    .mt-lg-2 {
      margin-top: .5rem !important
    }
    .mt-lg-3 {
      margin-top: 1rem !important
    }
    .mt-lg-4 {
      margin-top: 1.5rem !important
    }
    .mt-lg-5 {
      margin-top: 3rem !important
    }
    .mt-lg-auto {
      margin-top: auto !important
    }
    .me-lg-0 {
      margin-right: 0 !important
    }
    .me-lg-1 {
      margin-right: .25rem !important
    }
    .me-lg-2 {
      margin-right: .5rem !important
    }
    .me-lg-3 {
      margin-right: 1rem !important
    }
    .me-lg-4 {
      margin-right: 1.5rem !important
    }
    .me-lg-5 {
      margin-right: 3rem !important
    }
    .me-lg-auto {
      margin-right: auto !important
    }
    .mb-lg-0 {
      margin-bottom: 0 !important
    }
    .mb-lg-1 {
      margin-bottom: .25rem !important
    }
    .mb-lg-2 {
      margin-bottom: .5rem !important
    }
    .mb-lg-3 {
      margin-bottom: 1rem !important
    }
    .mb-lg-4 {
      margin-bottom: 1.5rem !important
    }
    .mb-lg-5 {
      margin-bottom: 3rem !important
    }
    .mb-lg-auto {
      margin-bottom: auto !important
    }
    .ms-lg-0 {
      margin-left: 0 !important
    }
    .ms-lg-1 {
      margin-left: .25rem !important
    }
    .ms-lg-2 {
      margin-left: .5rem !important
    }
    .ms-lg-3 {
      margin-left: 1rem !important
    }
    .ms-lg-4 {
      margin-left: 1.5rem !important
    }
    .ms-lg-5 {
      margin-left: 3rem !important
    }
    .ms-lg-auto {
      margin-left: auto !important
    }
    .p-lg-0 {
      padding: 0 !important
    }
    .p-lg-1 {
      padding: .25rem !important
    }
    .p-lg-2 {
      padding: .5rem !important
    }
    .p-lg-3 {
      padding: 1rem !important
    }
    .p-lg-4 {
      padding: 1.5rem !important
    }
    .p-lg-5 {
      padding: 3rem !important
    }
    .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important
    }
    .px-lg-1 {
      padding-right: .25rem !important;
      padding-left: .25rem !important
    }
    .px-lg-2 {
      padding-right: .5rem !important;
      padding-left: .5rem !important
    }
    .px-lg-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important
    }
    .px-lg-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important
    }
    .px-lg-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important
    }
    .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important
    }
    .py-lg-1 {
      padding-top: .25rem !important;
      padding-bottom: .25rem !important
    }
    .py-lg-2 {
      padding-top: .5rem !important;
      padding-bottom: .5rem !important
    }
    .py-lg-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important
    }
    .py-lg-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important
    }
    .py-lg-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important
    }
    .pt-lg-0 {
      padding-top: 0 !important
    }
    .pt-lg-1 {
      padding-top: .25rem !important
    }
    .pt-lg-2 {
      padding-top: .5rem !important
    }
    .pt-lg-3 {
      padding-top: 1rem !important
    }
    .pt-lg-4 {
      padding-top: 1.5rem !important
    }
    .pt-lg-5 {
      padding-top: 3rem !important
    }
    .pe-lg-0 {
      padding-right: 0 !important
    }
    .pe-lg-1 {
      padding-right: .25rem !important
    }
    .pe-lg-2 {
      padding-right: .5rem !important
    }
    .pe-lg-3 {
      padding-right: 1rem !important
    }
    .pe-lg-4 {
      padding-right: 1.5rem !important
    }
    .pe-lg-5 {
      padding-right: 3rem !important
    }
    .pb-lg-0 {
      padding-bottom: 0 !important
    }
    .pb-lg-1 {
      padding-bottom: .25rem !important
    }
    .pb-lg-2 {
      padding-bottom: .5rem !important
    }
    .pb-lg-3 {
      padding-bottom: 1rem !important
    }
    .pb-lg-4 {
      padding-bottom: 1.5rem !important
    }
    .pb-lg-5 {
      padding-bottom: 3rem !important
    }
    .ps-lg-0 {
      padding-left: 0 !important
    }
    .ps-lg-1 {
      padding-left: .25rem !important
    }
    .ps-lg-2 {
      padding-left: .5rem !important
    }
    .ps-lg-3 {
      padding-left: 1rem !important
    }
    .ps-lg-4 {
      padding-left: 1.5rem !important
    }
    .ps-lg-5 {
      padding-left: 3rem !important
    }
    .text-lg-start {
      text-align: left !important
    }
    .text-lg-end {
      text-align: right !important
    }
    .text-lg-center {
      text-align: center !important
    }
  }
  @media (min-width: 1200px) {
    .float-xl-start {
      float: left !important
    }
    .float-xl-end {
      float: right !important
    }
    .float-xl-none {
      float: none !important
    }
    .d-xl-inline {
      display: inline !important
    }
    .d-xl-inline-block {
      display: inline-block !important
    }
    .d-xl-block {
      display: block !important
    }
    .d-xl-grid {
      display: grid !important
    }
    .d-xl-table {
      display: table !important
    }
    .d-xl-table-row {
      display: table-row !important
    }
    .d-xl-table-cell {
      display: table-cell !important
    }
    .d-xl-flex {
      display: flex !important
    }
    .d-xl-inline-flex {
      display: inline-flex !important
    }
    .d-xl-none {
      display: none !important
    }
    .flex-xl-fill {
      flex: 1 1 auto !important
    }
    .flex-xl-row {
      flex-direction: row !important
    }
    .flex-xl-column {
      flex-direction: column !important
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important
    }
    .flex-xl-grow-0 {
      flex-grow: 0 !important
    }
    .flex-xl-grow-1 {
      flex-grow: 1 !important
    }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important
    }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important
    }
    .gap-xl-0 {
      gap: 0 !important
    }
    .gap-xl-1 {
      gap: .25rem !important
    }
    .gap-xl-2 {
      gap: .5rem !important
    }
    .gap-xl-3 {
      gap: 1rem !important
    }
    .gap-xl-4 {
      gap: 1.5rem !important
    }
    .gap-xl-5 {
      gap: 3rem !important
    }
    .justify-content-xl-start {
      justify-content: flex-start !important
    }
    .justify-content-xl-end {
      justify-content: flex-end !important
    }
    .justify-content-xl-center {
      justify-content: center !important
    }
    .justify-content-xl-between {
      justify-content: space-between !important
    }
    .justify-content-xl-around {
      justify-content: space-around !important
    }
    .justify-content-xl-evenly {
      justify-content: space-evenly !important
    }
    .align-items-xl-start {
      align-items: flex-start !important
    }
    .align-items-xl-end {
      align-items: flex-end !important
    }
    .align-items-xl-center {
      align-items: center !important
    }
    .align-items-xl-baseline {
      align-items: baseline !important
    }
    .align-items-xl-stretch {
      align-items: stretch !important
    }
    .align-content-xl-start {
      align-content: flex-start !important
    }
    .align-content-xl-end {
      align-content: flex-end !important
    }
    .align-content-xl-center {
      align-content: center !important
    }
    .align-content-xl-between {
      align-content: space-between !important
    }
    .align-content-xl-around {
      align-content: space-around !important
    }
    .align-content-xl-stretch {
      align-content: stretch !important
    }
    .align-self-xl-auto {
      align-self: auto !important
    }
    .align-self-xl-start {
      align-self: flex-start !important
    }
    .align-self-xl-end {
      align-self: flex-end !important
    }
    .align-self-xl-center {
      align-self: center !important
    }
    .align-self-xl-baseline {
      align-self: baseline !important
    }
    .align-self-xl-stretch {
      align-self: stretch !important
    }
    .order-xl-first {
      order: -1 !important
    }
    .order-xl-0 {
      order: 0 !important
    }
    .order-xl-1 {
      order: 1 !important
    }
    .order-xl-2 {
      order: 2 !important
    }
    .order-xl-3 {
      order: 3 !important
    }
    .order-xl-4 {
      order: 4 !important
    }
    .order-xl-5 {
      order: 5 !important
    }
    .order-xl-last {
      order: 6 !important
    }
    .m-xl-0 {
      margin: 0 !important
    }
    .m-xl-1 {
      margin: .25rem !important
    }
    .m-xl-2 {
      margin: .5rem !important
    }
    .m-xl-3 {
      margin: 1rem !important
    }
    .m-xl-4 {
      margin: 1.5rem !important
    }
    .m-xl-5 {
      margin: 3rem !important
    }
    .m-xl-auto {
      margin: auto !important
    }
    .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important
    }
    .mx-xl-1 {
      margin-right: .25rem !important;
      margin-left: .25rem !important
    }
    .mx-xl-2 {
      margin-right: .5rem !important;
      margin-left: .5rem !important
    }
    .mx-xl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important
    }
    .mx-xl-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important
    }
    .mx-xl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important
    }
    .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important
    }
    .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important
    }
    .my-xl-1 {
      margin-top: .25rem !important;
      margin-bottom: .25rem !important
    }
    .my-xl-2 {
      margin-top: .5rem !important;
      margin-bottom: .5rem !important
    }
    .my-xl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important
    }
    .my-xl-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important
    }
    .my-xl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important
    }
    .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important
    }
    .mt-xl-0 {
      margin-top: 0 !important
    }
    .mt-xl-1 {
      margin-top: .25rem !important
    }
    .mt-xl-2 {
      margin-top: .5rem !important
    }
    .mt-xl-3 {
      margin-top: 1rem !important
    }
    .mt-xl-4 {
      margin-top: 1.5rem !important
    }
    .mt-xl-5 {
      margin-top: 3rem !important
    }
    .mt-xl-auto {
      margin-top: auto !important
    }
    .me-xl-0 {
      margin-right: 0 !important
    }
    .me-xl-1 {
      margin-right: .25rem !important
    }
    .me-xl-2 {
      margin-right: .5rem !important
    }
    .me-xl-3 {
      margin-right: 1rem !important
    }
    .me-xl-4 {
      margin-right: 1.5rem !important
    }
    .me-xl-5 {
      margin-right: 3rem !important
    }
    .me-xl-auto {
      margin-right: auto !important
    }
    .mb-xl-0 {
      margin-bottom: 0 !important
    }
    .mb-xl-1 {
      margin-bottom: .25rem !important
    }
    .mb-xl-2 {
      margin-bottom: .5rem !important
    }
    .mb-xl-3 {
      margin-bottom: 1rem !important
    }
    .mb-xl-4 {
      margin-bottom: 1.5rem !important
    }
    .mb-xl-5 {
      margin-bottom: 3rem !important
    }
    .mb-xl-auto {
      margin-bottom: auto !important
    }
    .ms-xl-0 {
      margin-left: 0 !important
    }
    .ms-xl-1 {
      margin-left: .25rem !important
    }
    .ms-xl-2 {
      margin-left: .5rem !important
    }
    .ms-xl-3 {
      margin-left: 1rem !important
    }
    .ms-xl-4 {
      margin-left: 1.5rem !important
    }
    .ms-xl-5 {
      margin-left: 3rem !important
    }
    .ms-xl-auto {
      margin-left: auto !important
    }
    .p-xl-0 {
      padding: 0 !important
    }
    .p-xl-1 {
      padding: .25rem !important
    }
    .p-xl-2 {
      padding: .5rem !important
    }
    .p-xl-3 {
      padding: 1rem !important
    }
    .p-xl-4 {
      padding: 1.5rem !important
    }
    .p-xl-5 {
      padding: 3rem !important
    }
    .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important
    }
    .px-xl-1 {
      padding-right: .25rem !important;
      padding-left: .25rem !important
    }
    .px-xl-2 {
      padding-right: .5rem !important;
      padding-left: .5rem !important
    }
    .px-xl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important
    }
    .px-xl-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important
    }
    .px-xl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important
    }
    .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important
    }
    .py-xl-1 {
      padding-top: .25rem !important;
      padding-bottom: .25rem !important
    }
    .py-xl-2 {
      padding-top: .5rem !important;
      padding-bottom: .5rem !important
    }
    .py-xl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important
    }
    .py-xl-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important
    }
    .py-xl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important
    }
    .pt-xl-0 {
      padding-top: 0 !important
    }
    .pt-xl-1 {
      padding-top: .25rem !important
    }
    .pt-xl-2 {
      padding-top: .5rem !important
    }
    .pt-xl-3 {
      padding-top: 1rem !important
    }
    .pt-xl-4 {
      padding-top: 1.5rem !important
    }
    .pt-xl-5 {
      padding-top: 3rem !important
    }
    .pe-xl-0 {
      padding-right: 0 !important
    }
    .pe-xl-1 {
      padding-right: .25rem !important
    }
    .pe-xl-2 {
      padding-right: .5rem !important
    }
    .pe-xl-3 {
      padding-right: 1rem !important
    }
    .pe-xl-4 {
      padding-right: 1.5rem !important
    }
    .pe-xl-5 {
      padding-right: 3rem !important
    }
    .pb-xl-0 {
      padding-bottom: 0 !important
    }
    .pb-xl-1 {
      padding-bottom: .25rem !important
    }
    .pb-xl-2 {
      padding-bottom: .5rem !important
    }
    .pb-xl-3 {
      padding-bottom: 1rem !important
    }
    .pb-xl-4 {
      padding-bottom: 1.5rem !important
    }
    .pb-xl-5 {
      padding-bottom: 3rem !important
    }
    .ps-xl-0 {
      padding-left: 0 !important
    }
    .ps-xl-1 {
      padding-left: .25rem !important
    }
    .ps-xl-2 {
      padding-left: .5rem !important
    }
    .ps-xl-3 {
      padding-left: 1rem !important
    }
    .ps-xl-4 {
      padding-left: 1.5rem !important
    }
    .ps-xl-5 {
      padding-left: 3rem !important
    }
    .text-xl-start {
      text-align: left !important
    }
    .text-xl-end {
      text-align: right !important
    }
    .text-xl-center {
      text-align: center !important
    }
  }
  @media (min-width: 1400px) {
    .float-xxl-start {
      float: left !important
    }
    .float-xxl-end {
      float: right !important
    }
    .float-xxl-none {
      float: none !important
    }
    .d-xxl-inline {
      display: inline !important
    }
    .d-xxl-inline-block {
      display: inline-block !important
    }
    .d-xxl-block {
      display: block !important
    }
    .d-xxl-grid {
      display: grid !important
    }
    .d-xxl-table {
      display: table !important
    }
    .d-xxl-table-row {
      display: table-row !important
    }
    .d-xxl-table-cell {
      display: table-cell !important
    }
    .d-xxl-flex {
      display: flex !important
    }
    .d-xxl-inline-flex {
      display: inline-flex !important
    }
    .d-xxl-none {
      display: none !important
    }
    .flex-xxl-fill {
      flex: 1 1 auto !important
    }
    .flex-xxl-row {
      flex-direction: row !important
    }
    .flex-xxl-column {
      flex-direction: column !important
    }
    .flex-xxl-row-reverse {
      flex-direction: row-reverse !important
    }
    .flex-xxl-column-reverse {
      flex-direction: column-reverse !important
    }
    .flex-xxl-grow-0 {
      flex-grow: 0 !important
    }
    .flex-xxl-grow-1 {
      flex-grow: 1 !important
    }
    .flex-xxl-shrink-0 {
      flex-shrink: 0 !important
    }
    .flex-xxl-shrink-1 {
      flex-shrink: 1 !important
    }
    .flex-xxl-wrap {
      flex-wrap: wrap !important
    }
    .flex-xxl-nowrap {
      flex-wrap: nowrap !important
    }
    .flex-xxl-wrap-reverse {
      flex-wrap: wrap-reverse !important
    }
    .gap-xxl-0 {
      gap: 0 !important
    }
    .gap-xxl-1 {
      gap: .25rem !important
    }
    .gap-xxl-2 {
      gap: .5rem !important
    }
    .gap-xxl-3 {
      gap: 1rem !important
    }
    .gap-xxl-4 {
      gap: 1.5rem !important
    }
    .gap-xxl-5 {
      gap: 3rem !important
    }
    .justify-content-xxl-start {
      justify-content: flex-start !important
    }
    .justify-content-xxl-end {
      justify-content: flex-end !important
    }
    .justify-content-xxl-center {
      justify-content: center !important
    }
    .justify-content-xxl-between {
      justify-content: space-between !important
    }
    .justify-content-xxl-around {
      justify-content: space-around !important
    }
    .justify-content-xxl-evenly {
      justify-content: space-evenly !important
    }
    .align-items-xxl-start {
      align-items: flex-start !important
    }
    .align-items-xxl-end {
      align-items: flex-end !important
    }
    .align-items-xxl-center {
      align-items: center !important
    }
    .align-items-xxl-baseline {
      align-items: baseline !important
    }
    .align-items-xxl-stretch {
      align-items: stretch !important
    }
    .align-content-xxl-start {
      align-content: flex-start !important
    }
    .align-content-xxl-end {
      align-content: flex-end !important
    }
    .align-content-xxl-center {
      align-content: center !important
    }
    .align-content-xxl-between {
      align-content: space-between !important
    }
    .align-content-xxl-around {
      align-content: space-around !important
    }
    .align-content-xxl-stretch {
      align-content: stretch !important
    }
    .align-self-xxl-auto {
      align-self: auto !important
    }
    .align-self-xxl-start {
      align-self: flex-start !important
    }
    .align-self-xxl-end {
      align-self: flex-end !important
    }
    .align-self-xxl-center {
      align-self: center !important
    }
    .align-self-xxl-baseline {
      align-self: baseline !important
    }
    .align-self-xxl-stretch {
      align-self: stretch !important
    }
    .order-xxl-first {
      order: -1 !important
    }
    .order-xxl-0 {
      order: 0 !important
    }
    .order-xxl-1 {
      order: 1 !important
    }
    .order-xxl-2 {
      order: 2 !important
    }
    .order-xxl-3 {
      order: 3 !important
    }
    .order-xxl-4 {
      order: 4 !important
    }
    .order-xxl-5 {
      order: 5 !important
    }
    .order-xxl-last {
      order: 6 !important
    }
    .m-xxl-0 {
      margin: 0 !important
    }
    .m-xxl-1 {
      margin: .25rem !important
    }
    .m-xxl-2 {
      margin: .5rem !important
    }
    .m-xxl-3 {
      margin: 1rem !important
    }
    .m-xxl-4 {
      margin: 1.5rem !important
    }
    .m-xxl-5 {
      margin: 3rem !important
    }
    .m-xxl-auto {
      margin: auto !important
    }
    .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important
    }
    .mx-xxl-1 {
      margin-right: .25rem !important;
      margin-left: .25rem !important
    }
    .mx-xxl-2 {
      margin-right: .5rem !important;
      margin-left: .5rem !important
    }
    .mx-xxl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important
    }
    .mx-xxl-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important
    }
    .mx-xxl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important
    }
    .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important
    }
    .my-xxl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important
    }
    .my-xxl-1 {
      margin-top: .25rem !important;
      margin-bottom: .25rem !important
    }
    .my-xxl-2 {
      margin-top: .5rem !important;
      margin-bottom: .5rem !important
    }
    .my-xxl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important
    }
    .my-xxl-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important
    }
    .my-xxl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important
    }
    .my-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important
    }
    .mt-xxl-0 {
      margin-top: 0 !important
    }
    .mt-xxl-1 {
      margin-top: .25rem !important
    }
    .mt-xxl-2 {
      margin-top: .5rem !important
    }
    .mt-xxl-3 {
      margin-top: 1rem !important
    }
    .mt-xxl-4 {
      margin-top: 1.5rem !important
    }
    .mt-xxl-5 {
      margin-top: 3rem !important
    }
    .mt-xxl-auto {
      margin-top: auto !important
    }
    .me-xxl-0 {
      margin-right: 0 !important
    }
    .me-xxl-1 {
      margin-right: .25rem !important
    }
    .me-xxl-2 {
      margin-right: .5rem !important
    }
    .me-xxl-3 {
      margin-right: 1rem !important
    }
    .me-xxl-4 {
      margin-right: 1.5rem !important
    }
    .me-xxl-5 {
      margin-right: 3rem !important
    }
    .me-xxl-auto {
      margin-right: auto !important
    }
    .mb-xxl-0 {
      margin-bottom: 0 !important
    }
    .mb-xxl-1 {
      margin-bottom: .25rem !important
    }
    .mb-xxl-2 {
      margin-bottom: .5rem !important
    }
    .mb-xxl-3 {
      margin-bottom: 1rem !important
    }
    .mb-xxl-4 {
      margin-bottom: 1.5rem !important
    }
    .mb-xxl-5 {
      margin-bottom: 3rem !important
    }
    .mb-xxl-auto {
      margin-bottom: auto !important
    }
    .ms-xxl-0 {
      margin-left: 0 !important
    }
    .ms-xxl-1 {
      margin-left: .25rem !important
    }
    .ms-xxl-2 {
      margin-left: .5rem !important
    }
    .ms-xxl-3 {
      margin-left: 1rem !important
    }
    .ms-xxl-4 {
      margin-left: 1.5rem !important
    }
    .ms-xxl-5 {
      margin-left: 3rem !important
    }
    .ms-xxl-auto {
      margin-left: auto !important
    }
    .p-xxl-0 {
      padding: 0 !important
    }
    .p-xxl-1 {
      padding: .25rem !important
    }
    .p-xxl-2 {
      padding: .5rem !important
    }
    .p-xxl-3 {
      padding: 1rem !important
    }
    .p-xxl-4 {
      padding: 1.5rem !important
    }
    .p-xxl-5 {
      padding: 3rem !important
    }
    .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important
    }
    .px-xxl-1 {
      padding-right: .25rem !important;
      padding-left: .25rem !important
    }
    .px-xxl-2 {
      padding-right: .5rem !important;
      padding-left: .5rem !important
    }
    .px-xxl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important
    }
    .px-xxl-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important
    }
    .px-xxl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important
    }
    .py-xxl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important
    }
    .py-xxl-1 {
      padding-top: .25rem !important;
      padding-bottom: .25rem !important
    }
    .py-xxl-2 {
      padding-top: .5rem !important;
      padding-bottom: .5rem !important
    }
    .py-xxl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important
    }
    .py-xxl-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important
    }
    .py-xxl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important
    }
    .pt-xxl-0 {
      padding-top: 0 !important
    }
    .pt-xxl-1 {
      padding-top: .25rem !important
    }
    .pt-xxl-2 {
      padding-top: .5rem !important
    }
    .pt-xxl-3 {
      padding-top: 1rem !important
    }
    .pt-xxl-4 {
      padding-top: 1.5rem !important
    }
    .pt-xxl-5 {
      padding-top: 3rem !important
    }
    .pe-xxl-0 {
      padding-right: 0 !important
    }
    .pe-xxl-1 {
      padding-right: .25rem !important
    }
    .pe-xxl-2 {
      padding-right: .5rem !important
    }
    .pe-xxl-3 {
      padding-right: 1rem !important
    }
    .pe-xxl-4 {
      padding-right: 1.5rem !important
    }
    .pe-xxl-5 {
      padding-right: 3rem !important
    }
    .pb-xxl-0 {
      padding-bottom: 0 !important
    }
    .pb-xxl-1 {
      padding-bottom: .25rem !important
    }
    .pb-xxl-2 {
      padding-bottom: .5rem !important
    }
    .pb-xxl-3 {
      padding-bottom: 1rem !important
    }
    .pb-xxl-4 {
      padding-bottom: 1.5rem !important
    }
    .pb-xxl-5 {
      padding-bottom: 3rem !important
    }
    .ps-xxl-0 {
      padding-left: 0 !important
    }
    .ps-xxl-1 {
      padding-left: .25rem !important
    }
    .ps-xxl-2 {
      padding-left: .5rem !important
    }
    .ps-xxl-3 {
      padding-left: 1rem !important
    }
    .ps-xxl-4 {
      padding-left: 1.5rem !important
    }
    .ps-xxl-5 {
      padding-left: 3rem !important
    }
    .text-xxl-start {
      text-align: left !important
    }
    .text-xxl-end {
      text-align: right !important
    }
    .text-xxl-center {
      text-align: center !important
    }
  }
  @media (min-width: 1200px) {
    .fs-1 {
      font-size: 2.5rem !important
    }
    .fs-2 {
      font-size: 2rem !important
    }
    .fs-3 {
      font-size: 1.75rem !important
    }
    .fs-4 {
      font-size: 1.5rem !important
    }
  }
  @media print {
    .d-print-inline {
      display: inline !important
    }
    .d-print-inline-block {
      display: inline-block !important
    }
    .d-print-block {
      display: block !important
    }
    .d-print-grid {
      display: grid !important
    }
    .d-print-table {
      display: table !important
    }
    .d-print-table-row {
      display: table-row !important
    }
    .d-print-table-cell {
      display: table-cell !important
    }
    .d-print-flex {
      display: flex !important
    }
    .d-print-inline-flex {
      display: inline-flex !important
    }
    .d-print-none {
      display: none !important
    }
  }

  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: .5rem
  }

  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/")
  }

  .breadcrumb-item.active {
    color: #6c757d
  }

  .btn, .btn-block, .btn-gray, .btn-outline, .portfolio-tags .portfolio-tags__item {
    display: inline-flex;
    align-items: center;
    align-self: start;
    justify-content: center;
    background: linear-gradient(180deg, #ff7c24 0, #ff3e3b 100%);
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    line-height: 1;
    padding: 1rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 1rem;
    letter-spacing: .03rem;
    font-weight: 500;
    color: #fff;
    transition: .15s
  }

  @media (prefers-reduced-motion: reduce) {
    .btn, .btn-block, .btn-gray, .btn-outline, .portfolio-tags .portfolio-tags__item {
      transition: none
    }
  }

  .btn-block:hover, .btn-gray:hover, .btn-outline:hover, .btn:hover, .portfolio-tags .portfolio-tags__item:hover {
    background: linear-gradient(180deg, #ff7c24 15%, #ff3e3b 85%);
    color: #fff;
    text-decoration: none
  }

  .btn-block:focus, .btn-gray:focus, .btn-outline:focus, .btn:focus, .focus.btn, .focus.btn-block, .focus.btn-gray, .focus.btn-outline, .portfolio-tags .focus.portfolio-tags__item, .portfolio-tags .portfolio-tags__item:focus {
    outline: 0
  }

  .portfolio-tags [disabled=disabled].portfolio-tags__item, [disabled=disabled].btn, [disabled=disabled].btn-block, [disabled=disabled].btn-gray, [disabled=disabled].btn-outline {
    opacity: .55;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none
  }

  .btn svg, .btn-block svg, .btn-gray svg, .btn-outline svg, .portfolio-tags .portfolio-tags__item svg {
    top: 0
  }

  .btn-outline, .portfolio-tags .portfolio-tags__item {
    color: #121212;
    background: unset;
    background-color: #fff;
    border: .125rem solid #d0d0d0
  }

  .btn-outline:hover, .portfolio-tags .portfolio-tags__item:hover {
    background: unset;
    background-color: #ff5c30;
    color: #fff;
    border-color: #ff5c30
  }

  .btn-gray {
    background: #e8e8e8;
    color: #121212
  }

  .btn-block {
    width: 100%
  }

  .btn-block + .btn-block {
    margin-top: .5rem
  }

  .btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
    margin-left: -2px
  }

  .btn-check:active + .btn, .btn-check:checked + .btn, .btn.active, .btn.dropdown-toggle.show, .btn:active {
    background: unset;
    background-color: #ff5c30;
    color: #fff;
    border-color: #ff5c30
  }

  .btn-group .btn {
    background: unset;
    padding: .5625rem .8125rem;
    font-size: .9375rem;
    font-weight: 400;
    background-color: #fff;
    color: #121212;
    border: .125rem solid #d0d0d0;
    letter-spacing: unset
  }

  .btn-group .btn:hover {
    background: unset;
    background-color: #ff5c30;
    color: #fff;
    border-color: #ff5c30
  }

  @media (min-width: 992px) {
    .desktop-header {
      padding-top: .75rem;
      padding-bottom: 1.25rem
    }
  }
  @media (max-width: 991.98px) {
    .desktop-header .desktop-header__menu {
      padding: 0
    }
  }

  .desktop-header .desktop-header__menu .nav-item:not(:last-child) {
    margin-right: 2.5rem
  }

  @media (max-width: 1199.98px) {
    .desktop-header .desktop-header__menu .nav-item:not(:last-child) {
      margin-right: 1.5rem
    }
  }
  @media (max-width: 991.98px) {
    .desktop-header .desktop-header__menu .nav-item:not(:last-child) {
      margin-right: 0
    }
  }

  .desktop-header .desktop-header__menu .desktop-header__menu_order {
    padding: .875rem 1.75rem
  }

  @media (max-width: 991.98px) {
    .desktop-header .desktop-header__menu .desktop-header__menu_order {
      padding: 1rem;
      margin-bottom: 1.5rem;
      display: block;
      width: 100%
    }
  }
  @media (max-width: 991.98px) {
    .desktop-header .desktop-header__menu .menu_mobile__nav__wrap_content {
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 0
    }
  }

  .desktop-header .desktop-header__menu .desktop-header__menu_item {
    font-size: 1.125rem;
    font-weight: 500;
    text-decoration: none;
    color: #121212
  }

  @media (max-width: 991.98px) {
    .desktop-header .desktop-header__menu .desktop-header__menu_item {
      display: block;
      padding: .25rem 0
    }
  }

  .desktop-header .desktop-header__menu .desktop-header__menu_item:hover {
    color: #ff7c24
  }

  .desktop-header .desktop-header__menu .desktop-header__menu_item.active {
    color: #ff7c24;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none
  }

  .desktop-header .navbar a {
    text-decoration: none;
    color: #121212
  }

  .desktop-header .navbar a:hover {
    color: #ff7c24
  }

  .desktop-header .navbar .navbar-brand {
    display: flex;
    align-items: center
  }

  @media (max-width: 575.98px) {
    .desktop-header .navbar .navbar-brand {
      width: 4rem;
      padding: 0
    }
  }

  .desktop-header .navbar .navbar-brand .logo {
    display: block
  }

  .desktop-header .navbar .navbar-brand .description {
    margin-left: 2.5rem;
    color: #a0a0a0;
    line-height: 1.3;
    font-size: 1.125rem
  }

  @media (max-width: 991.98px) {
    .desktop-header .navbar .navbar-brand .description {
      display: none
    }
  }

  .desktop-header .navbar .navbar-brand .description .small {
    font-size: .8125rem
  }

  .desktop-header .navbar .navbar-phone {
    margin-bottom: 0
  }

  .desktop-header .navbar .navbar-phone .navbar-phone__socials .navbar-phone__socials-item a {
    display: block
  }

  .desktop-header .navbar .navbar-phone .navbar-phone__socials .navbar-phone__socials-item:not(:last-child) {
    margin-right: .375rem
  }

  .desktop-header .navbar .navbar-phone .navbar-phone__tel {
    margin-right: 1.25rem
  }

  @media (max-width: 767.98px) {
    .desktop-header .navbar .navbar-phone .navbar-phone__tel {
      margin-right: 0
    }
  }

  .desktop-header .navbar .navbar-phone .navbar-phone__tel a {
    font-size: 1.375rem;
    font-weight: 600;
    display: block
  }

  @media (max-width: 575.98px) {
    .desktop-header .navbar .navbar-phone .navbar-phone__tel a {
      font-size: 1.25rem;
      font-weight: 600
    }
  }
  @media (max-width: 374px) {
    .desktop-header .navbar .navbar-phone .navbar-phone__tel a {
      font-size: 1.125rem
    }
  }

  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: .5rem
  }

  .navbar > .container, .navbar > .container-fluid, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between
  }

  .navbar-brand {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
  }

  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
  }

  .navbar-nav .dropdown-menu {
    position: static
  }

  .navbar .burger {
    background: 0 0;
    border: none;
    padding: 0;
    margin-left: 1.5rem;
    -webkit-tap-highlight-color: rgba(255, 255, 255, .1)
  }

  @media (min-width: 992px) {
    .navbar .burger {
      display: none
    }
  }

  .navbar .burger:active, .navbar .burger:focus {
    outline: 0
  }

  .navbar .burger .icon-bar {
    display: block;
    width: 25px;
    height: 2px;
    margin: 5px 0;
    transition: .15s;
    background: #121212
  }

  @media (prefers-reduced-motion: reduce) {
    .navbar .burger .icon-bar {
      transition: none
    }
  }

  .navbar .burger .icon-bar:nth-of-type(1) {
    transform: rotate(45deg);
    transform-origin: 10% 20%
  }

  .navbar .burger .icon-bar:nth-of-type(2) {
    opacity: 0
  }

  .navbar .burger .icon-bar:nth-of-type(3) {
    transform: rotate(-45deg);
    transform-origin: 10% 90%
  }

  .navbar .burger.collapsed .icon-bar {
    background: #121212
  }

  .navbar .burger.collapsed .icon-bar:nth-of-type(1) {
    transform: rotate(0)
  }

  .navbar .burger.collapsed .icon-bar:nth-of-type(2) {
    opacity: 1
  }

  .navbar .burger.collapsed .icon-bar:nth-of-type(3) {
    transform: rotate(0)
  }

  .navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
  }

  .navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 0;
    transition: box-shadow .15s ease-in-out;
    color: #121212
  }

  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none
    }
  }

  .navbar-toggler:hover {
    text-decoration: none
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
  }

  .navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
  }

  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-wrap: nowrap;
      justify-content: flex-start
    }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem
    }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible
    }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto
    }
    .navbar-expand-sm .navbar-toggler {
      display: none
    }
    .navbar-expand-sm .offcanvas-header {
      display: none
    }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none
    }
    .navbar-expand-sm .offcanvas-bottom, .navbar-expand-sm .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0
    }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-wrap: nowrap;
      justify-content: flex-start
    }
    .navbar-expand-md .navbar-nav {
      flex-direction: row
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem
    }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible
    }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto
    }
    .navbar-expand-md .navbar-toggler {
      display: none
    }
    .navbar-expand-md .offcanvas-header {
      display: none
    }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none
    }
    .navbar-expand-md .offcanvas-bottom, .navbar-expand-md .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0
    }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-wrap: nowrap;
      justify-content: flex-start
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem
    }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible
    }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto
    }
    .navbar-expand-lg .navbar-toggler {
      display: none
    }
    .navbar-expand-lg .offcanvas-header {
      display: none
    }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none
    }
    .navbar-expand-lg .offcanvas-bottom, .navbar-expand-lg .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0
    }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-wrap: nowrap;
      justify-content: flex-start
    }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem
    }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible
    }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto
    }
    .navbar-expand-xl .navbar-toggler {
      display: none
    }
    .navbar-expand-xl .offcanvas-header {
      display: none
    }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none
    }
    .navbar-expand-xl .offcanvas-bottom, .navbar-expand-xl .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0
    }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible
    }
  }
  @media (min-width: 1400px) {
    .navbar-expand-xxl {
      flex-wrap: nowrap;
      justify-content: flex-start
    }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row
    }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
      position: absolute
    }
    .navbar-expand-xxl .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem
    }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible
    }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto
    }
    .navbar-expand-xxl .navbar-toggler {
      display: none
    }
    .navbar-expand-xxl .offcanvas-header {
      display: none
    }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none
    }
    .navbar-expand-xxl .offcanvas-bottom, .navbar-expand-xxl .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0
    }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible
    }
  }

  .navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
  }

  .navbar-expand .navbar-nav {
    flex-direction: row
  }

  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand .navbar-nav-scroll {
    overflow: visible
  }

  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .navbar-expand .navbar-toggler {
    display: none
  }

  .navbar-expand .offcanvas-header {
    display: none
  }

  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none
  }

  .navbar-expand .offcanvas-bottom, .navbar-expand .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0
  }

  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
  }

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none
  }

  .pagination .page-link {
    padding: .4375rem .875rem;
    font-size: 1rem;
    font-weight: 500
  }

  .page-link {
    position: relative;
    display: block;
    color: #121212;
    text-decoration: none;
    background-color: #fff;
    border: .125rem solid #d0d0d0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none
    }
  }

  .page-link:hover {
    z-index: 2;
    color: #121212;
    background-color: #fff;
    border-color: #ff5c30
  }

  .page-link:focus {
    z-index: 3;
    color: #121212;
    background-color: #fff
  }

  .page-item:not(:first-child) .page-link {
    margin-left: -.125rem
  }

  .page-item.active .page-link {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: 3;
    color: #fff;
    background-color: #ff5c30;
    border-color: #ff5c30
  }

  .page-item.disabled .page-link {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
    color: #121212;
    pointer-events: none;
    background-color: #fff;
    border-color: #d0d0d0
  }

  .page-link {
    padding: .25rem .5rem
  }

  .page-item:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
  }

  .page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
  }

  .svg_16, .svg_18, .svg_24, .svg_auto {
    display: inline-block;
    position: relative;
    top: -.0625rem;
    flex-shrink: 0;
    fill: currentColor
  }

  .svg_24 {
    width: 1.5rem;
    height: 1.5rem
  }

  .svg_18 {
    width: 1.125rem;
    height: 1.125rem
  }

  .svg_16 {
    width: 1rem;
    height: 1rem
  }

  .svg_auto {
    height: 1em;
    width: 1em
  }

  .page-hero {
    background-color: #f8f8f8;
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  @media (max-width: 991.98px) {
    .page-hero {
      padding-top: 2rem;
      padding-bottom: 2rem
    }
  }
  @media (max-width: 767.98px) {
    .page-hero {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem
    }
  }

  .page-hero .page-hero__row {
    align-items: center
  }

  .page-hero .page-hero__title {
    margin-bottom: 0
  }

  .page-hero .page-hero__subtitle {
    margin-top: 1rem;
    color: #717171;
    font-size: 1.1875rem;
    line-height: 1.5
  }

  @media (max-width: 767.98px) {
    .page-hero .page-hero__subtitle {
      font-size: 1.0625rem;
      line-height: 1.4;
      margin-top: .75rem
    }
  }
  @media (max-width: 991.98px) {
    .page-hero .page-hero__image {
      margin-bottom: 1.5rem
    }
  }

  .page-hero .page-hero__links {
    margin-top: 2rem
  }

  @media (max-width: 767.98px) {
    .page-hero .page-hero__links {
      margin-top: .75rem
    }
  }

  .page-hero .page-hero__links .list-inline-item:not(:last-child) {
    margin-right: .625rem;
    margin-bottom: .375rem
  }

  .page-hero .page-hero__links a {
    font-size: 1.0625rem;
    text-decoration: none;
    border-bottom: .0625rem solid rgba(26, 102, 255, .3)
  }

  .page-hero .page-hero__links a:hover {
    color: #ff7c24;
    border-bottom: .0625rem solid rgba(255, 124, 36, .7)
  }

  .page-hero .page-hero__btn {
    margin-top: 1.5rem
  }

  @media (max-width: 575.98px) {
    .page-hero .page-hero__btn {
      margin-top: .75rem;
      display: block;
      width: 100%
    }
  }

  .page-hero .page-hero__date {
    font-size: 1.25rem;
    color: #717171;
    margin-top: 2.5rem
  }

  @media (max-width: 767.98px) {
    .page-hero .page-hero__date {
      margin-top: .75rem;
      font-size: 1.125rem
    }
  }

  .page-hero .page-hero__share {
    margin-top: 1.5rem
  }

  @media (max-width: 767.98px) {
    .page-hero .page-hero__share {
      margin-top: .75rem
    }
  }

  .page-hero .page-hero__youtube {
    font-size: 1.0625rem;
    font-weight: 600;
    border: .125rem solid #ff3e3b;
    background-color: transparent;
    padding: .5rem 1.25rem;
    border-radius: .5rem;
    color: #ff3e3b;
    margin-top: 2rem;
    transition: .15s
  }

  @media (max-width: 575.98px) {
    .page-hero .page-hero__youtube {
      margin-top: .75rem;
      display: block;
      width: 100%
    }
  }
  @media (prefers-reduced-motion: reduce) {
    .page-hero .page-hero__youtube {
      transition: none
    }
  }

  .page-hero .page-hero__youtube:hover {
    background-color: rgba(255, 62, 59, .1)
  }

  .page-hero .page-hero__youtube .page-hero__youtube-icon {
    margin-right: .5rem;
    top: 0
  }

  .page-hero .page-hero__breadcrumbs {
    color: #a0a0a0;
    margin-bottom: 1.5rem
  }

  @media (max-width: 575.98px) {
    .page-hero .page-hero__breadcrumbs {
      margin-bottom: .75rem
    }
  }

  .page-hero .page-hero__breadcrumbs .page-hero__breadcrumbs-link {
    color: #a0a0a0;
    text-decoration: none;
    font-size: .9375rem;
    font-weight: 400
  }

  @media (max-width: 575.98px) {
    .page-hero .page-hero__breadcrumbs .page-hero__breadcrumbs-link {
      font-size: .8125rem
    }
  }

  .page-hero .page-hero__breadcrumbs .page-hero__breadcrumbs-link:hover {
    color: #1a66ff
  }

  .page-hero .page-hero__breadcrumbs .page-hero__breadcrumbs-link:hover span {
    color: #1a66ff;
    border-color: rgba(26, 102, 255, .3)
  }

  .page-hero .page-hero__breadcrumbs .page-hero__breadcrumbs-link span {
    margin-left: .5rem;
    border-bottom: .0625rem solid #d0d0d0
  }

  .dropdown, .dropend, .dropstart, .dropup {
    position: relative
  }

  .dropdown-toggle {
    white-space: nowrap
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
  }

  .dropdown-toggle:empty::after {
    margin-left: 0
  }

  .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .5rem
  }

  @media (min-width: 768px) {
    .dropdown-menu {
      box-shadow: 0 16px 16px rgba(0, 0, 0, .04), 0 3px 6px rgba(0, 0, 0, .12), 0 0 2px rgba(0, 0, 0, .06)
    }
  }

  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: .125rem
  }

  .dropdown-menu-start {
    --bs-position: start
  }

  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
  }

  .dropdown-menu-end {
    --bs-position: end
  }

  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
  }

  @media (min-width: 576px) {
    .dropdown-menu-sm-start {
      --bs-position: start
    }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0
    }
    .dropdown-menu-sm-end {
      --bs-position: end
    }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-start {
      --bs-position: start
    }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0
    }
    .dropdown-menu-md-end {
      --bs-position: end
    }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-start {
      --bs-position: start
    }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0
    }
    .dropdown-menu-lg-end {
      --bs-position: end
    }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-start {
      --bs-position: start
    }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0
    }
    .dropdown-menu-xl-end {
      --bs-position: end
    }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto
    }
  }
  @media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
      --bs-position: start
    }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0
    }
    .dropdown-menu-xxl-end {
      --bs-position: end
    }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto
    }
  }

  .dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
  }

  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
  }

  .dropup .dropdown-toggle:empty::after {
    margin-left: 0
  }

  .dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
  }

  .dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
  }

  .dropend .dropdown-toggle:empty::after {
    margin-left: 0
  }

  .dropend .dropdown-toggle::after {
    vertical-align: 0
  }

  .dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
  }

  .dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
  }

  .dropstart .dropdown-toggle::after {
    display: none
  }

  .dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
  }

  .dropstart .dropdown-toggle:empty::after {
    margin-left: 0
  }

  .dropstart .dropdown-toggle::before {
    vertical-align: 0
  }

  .dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, .15)
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: .4375rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0
  }

  @media (max-width: 991.98px) {
    .dropdown-item {
      white-space: unset
    }
  }

  .dropdown-item:focus, .dropdown-item:hover {
    color: #fff !important;
    text-decoration: none;
    background-color: #ff7c24
  }

  .dropdown-item.active {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none
  }

  .dropdown-item.active, .dropdown-item:active {
    color: #fff !important;
    text-decoration: none;
    background-color: #ff7c24
  }

  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent
  }

  .dropdown-menu.show {
    display: block
  }

  .dropdown-header {
    display: block;
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #6c757d;
    white-space: nowrap
  }

  .dropdown-item-text {
    display: block;
    padding: .25rem 1rem;
    color: #212529
  }

  .dropdown-menu-dark {
    color: #dee2e6;
    background-color: #343a40;
    border-color: rgba(0, 0, 0, .15)
  }

  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6
  }

  .dropdown-menu-dark .dropdown-item:focus, .dropdown-menu-dark .dropdown-item:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .15)
  }

  .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: #0d6efd
  }

  .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd
  }

  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, .15)
  }

  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6
  }

  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd
  }

  .index-categories .index-categories__item {
    padding: 1.5rem 1.5rem 1.5rem 0;
    border-radius: 1rem;
    transition: .15s
  }

  @media (prefers-reduced-motion: reduce) {
    .index-categories .index-categories__item {
      transition: none
    }
  }
  @media (max-width: 767.98px) {
    .index-categories .index-categories__item {
      padding: .5rem 0
    }
  }
  @media (min-width: 768px) {
    .index-categories .index-categories__item:hover {
      background-color: #f8f8f8;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem
    }
  }

  .index-categories .index-categories__item:hover .index-categories__icon {
    color: #ff7c24
  }

  .index-categories .index-categories__item .index-categories__icon {
    font-size: 3rem;
    font-weight: 600;
    color: #e8e8e8;
    display: block
  }

  @media (max-width: 767.98px) {
    .index-categories .index-categories__item .index-categories__icon {
      display: none
    }
  }

  .index-categories .index-categories__item .index-categories__body {
    margin-left: 1.5rem
  }

  @media (max-width: 767.98px) {
    .index-categories .index-categories__item .index-categories__body {
      margin-left: 0
    }
  }

  .index-categories .index-categories__item .index-categories__title {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600
  }

  @media (max-width: 767.98px) {
    .index-categories .index-categories__item .index-categories__title {
      font-size: 1.25rem;
      line-height: 1.3
    }
  }

  .index-categories .index-categories__item .index-categories__links {
    margin-top: .5rem;
    margin-bottom: 0
  }

  .index-categories .index-categories__item .index-categories__links a {
    line-height: 1.6
  }

  @media (max-width: 575.98px) {
    .index-categories .index-categories__item .index-categories__links a {
      line-height: 2
    }
  }

  .index-categories .index-categories__item .index-categories__links .list-inline-item:not(:last-child) {
    margin-right: 1rem
  }

  .index-about .index-about__item {
    border-radius: 1rem;
    padding: 1.5rem;
    background: #f8f8f8;
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between
  }

  .index-about .index-about__item:hover {
    background: linear-gradient(180deg, #ff7c24 0, #ff3e3b 100%)
  }

  .index-about .index-about__item:hover .index-about__link {
    color: #fff
  }

  .index-about .index-about__item:hover .index-about__title {
    color: #fff
  }

  .index-about .index-about__item:hover .index-about__icon svg {
    fill: #fff
  }

  .index-about .index-about__item .index-about__title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #121212;
    line-height: 1.3;
    padding-right: 3rem
  }

  @media (max-width: 767.98px) {
    .index-about .index-about__item .index-about__title {
      line-height: 1.2;
      font-size: 1.0625rem;
      font-weight: 700
    }
  }

  .index-about .index-about__item .index-about__link {
    color: #1a66ff;
    text-decoration: underline;
    margin-top: 1.5rem
  }

  @media (max-width: 767.98px) {
    .index-about .index-about__item .index-about__link {
      margin-top: .75rem
    }
  }

  .index-about .index-about__item .index-about__icon {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem
  }

  .index-about .index-about__item .index-about__icon svg {
    fill: #a0a0a0
  }

  .cta-form {
    background-color: #f8f8f8;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  @media (max-width: 767.98px) {
    .cta-form {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem
    }
  }
  @media (max-width: 575.98px) {
    .cta-form {
      padding-left: .5rem;
      padding-right: .5rem
    }
  }

  .cta-form .cta-form__title {
    margin-bottom: 0
  }

  .cta-form .cta-form__subtitle {
    margin-top: 1.5rem;
    color: #717171;
    font-size: 1.25rem;
    line-height: 1.5
  }

  @media (max-width: 767.98px) {
    .cta-form .cta-form__subtitle {
      font-size: .9375rem;
      line-height: 1.4;
      margin-top: .75rem
    }
  }

  .cta-form .cta-form__btn {
    margin-top: 2rem
  }

  @media (max-width: 767.98px) {
    .cta-form .cta-form__btn {
      margin-top: 1rem
    }
  }
  @media (max-width: 575.98px) {
    .cta-form .cta-form__btn {
      display: block;
      width: 100%
    }
  }

  .cta-form .cta-form__rotate360 {
    -webkit-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite
  }

  @media (max-width: 991.98px) {
    .cta-form .cta-form__rotate360 {
      display: none
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg)
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg)
    }
  }

  .portfolio-gallery .portfolio-gallery__item {
    display: inline-block;
    border-radius: 1rem
  }

  .portfolio-gallery .portfolio-gallery__item:hover {
    cursor: zoom-in;
    background: #ff7c24
  }

  .portfolio-gallery .portfolio-gallery__item:hover .portfolio-gallery__image {
    opacity: .6
  }

  .portfolio-gallery .portfolio-gallery__item .portfolio-gallery__image {
    border-radius: 1rem;
    transition: .15s
  }

  @media (prefers-reduced-motion: reduce) {
    .portfolio-gallery .portfolio-gallery__item .portfolio-gallery__image {
      transition: none
    }
  }
  @media (max-width: 575.98px) {
    .mobile-scroll-x {
      flex-wrap: nowrap;
      overflow-x: scroll;
      display: flex
    }
    .mobile-scroll-x .mobile-scroll-x__item {
      width: 88%
    }
  }

  .footer-wrap {
    background-color: #f8f8f8
  }

  .footer-wrap .list-inline, .footer-wrap .list-unstyled {
    margin-bottom: 0
  }

  .footer-wrap .footer-wrap__contacts-block {
    margin-top: 1.25rem
  }

  @media (max-width: 767.98px) {
    .footer-wrap .footer-wrap__menu {
      margin-bottom: 1.5rem
    }
  }

  .footer-wrap .footer-wrap__menu .footer-wrap__menu-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #121212;
    margin-bottom: 1.25rem;
    line-height: 1.2
  }

  @media (max-width: 767.98px) {
    .footer-wrap .footer-wrap__menu .footer-wrap__menu-title {
      margin-bottom: .5rem
    }
  }

  .footer-wrap .footer-wrap__menu li:not(:first-child) {
    margin-top: .5rem
  }

  .footer-wrap .footer-wrap__menu li span.active {
    color: #ff7c24
  }

  .footer-wrap .footer-wrap__menu li a {
    line-height: 1.2;
    text-decoration: none;
    border-bottom: .0625rem solid rgba(26, 102, 255, .3)
  }

  .footer-wrap .footer-wrap__menu li a:hover {
    color: #ff7c24;
    border-bottom: .0625rem solid rgba(255, 124, 36, .6)
  }

  .footer-wrap .footer-wrap__phones a {
    color: #121212;
    font-size: 1.125rem;
    font-weight: 600;
    text-decoration: none
  }

  .footer-wrap .footer-wrap__phones a:hover {
    color: #ff7c24
  }

  .footer-wrap .footer-wrap__socials a {
    display: block
  }

  .footer-wrap .footer-wrap__socials .list-inline-item:not(:last-child) {
    margin-right: 0
  }

  .footer-wrap .footer-wrap__top {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    font-size: .875rem
  }

  .footer-wrap .footer-wrap__bottom {
    font-size: .8125rem;
    color: #717171;
    border-top: .0625rem solid #e8e8e8;
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  @media (max-width: 767.98px) {
    .footer-wrap .footer-wrap__bottom .footer-wrap__copyrights {
      margin-bottom: .5rem
    }
  }

  .footer-wrap .footer-wrap__bottom a {
    color: #717171
  }

  .footer-wrap .footer-wrap__bottom a:hover {
    color: #ff7c24
  }

  input:focus:required:invalid, textarea:focus:required:invalid {
    border-color: #ff7c24 !important
  }

  input:focus:required:valid, textarea:focus:required:valid {
    border-color: #ff7c24 !important
  }

  input:required:valid, textarea:required:valid {
    border-color: #d0d0d0 !important
  }

  input:required:focus, textarea:required:focus {
    border-color: #ff7c24 !important
  }

  .fancybox__content :focus:not(.carousel__button.is-close) {
    outline: 0
  }

  .fancybox__content :focus {
    outline: 0
  }

  .modal-form {
    border-radius: 1rem;
    padding: 2rem
  }

  @media (max-width: 575.98px) {
    .modal-form {
      padding: 1.25rem
    }
  }

  .modal-form .modal-form__header {
    margin-bottom: 2rem
  }

  @media (max-width: 575.98px) {
    .modal-form .modal-form__header {
      margin-bottom: 1.25rem
    }
  }

  .modal-form .modal-form__header .modal-form__title {
    margin-bottom: 0
  }

  .modal-form .custom-file-input, .modal-form .form-floating {
    margin-bottom: 1.5rem
  }

  @media (max-width: 575.98px) {
    .modal-form .custom-file-input, .modal-form .form-floating {
      margin-bottom: .625rem
    }
  }

  .custom-file-input {
    height: 3.625rem
  }

  .custom-file-input .custom-file-input__label {
    width: 100%;
    border-radius: .5rem !important;
    background-color: #f8f8f8;
    transition: .15s;
    cursor: pointer
  }

  @media (prefers-reduced-motion: reduce) {
    .custom-file-input .custom-file-input__label {
      transition: none
    }
  }

  .custom-file-input .custom-file-input__label svg {
    fill: #a0a0a0;
    top: 0
  }

  .custom-file-input .custom-file-input__label:hover {
    cursor: pointer;
    border-color: #ff7c24
  }

  .custom-file-input .custom-file-input__label .label-text {
    font-size: .875rem;
    color: #717171;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    text-align: left;
    margin-left: .75rem;
    overflow: hidden
  }

  .custom-file-input .custom-file-input__label .label-text .title {
    line-height: 1.3;
    font-size: .875rem;
    color: #717171
  }

  .custom-file-input .custom-file-input__label .label-text .file-name {
    line-height: 1.3;
    font-size: 1rem;
    color: #121212;
    font-weight: 500;
    max-width: 12.5rem
  }

  .custom-file-input .custom-file-input__input {
    display: none
  }

  .form-check-input:checked {
    background-color: #ff7c24;
    border-color: #ff7c24
  }

  .form-check-input:focus {
    box-shadow: none;
    border-color: #ff7c24
  }

  .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e")
  }

  .form-switch .form-check-input:checked:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
  }

  .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: .125rem solid #d0d0d0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .5rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none
    }
  }

  .form-control[type=file] {
    overflow: hidden
  }

  .form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
  }

  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #ff7c24;
    outline: 0
  }

  .form-control::-webkit-date-and-time-value {
    height: 1.5em
  }

  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
  }

  .form-control::placeholder {
    color: #6c757d;
    opacity: 1
  }

  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
  }

  .form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: .125rem;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
  }

  .form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: .125rem;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none
    }
    .form-control::file-selector-button {
      transition: none
    }
  }

  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3
  }

  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3
  }

  .form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: .125rem;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none
    }
  }

  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3
  }

  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: .125rem 0
  }

  .form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
  }

  .form-control-sm {
    min-height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
  }

  .form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
  }

  .form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
  }

  .form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
  }

  .form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
  }

  .form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
  }

  .form-control-lg::file-selector-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
  }

  .form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
  }

  textarea.form-control {
    min-height: calc(1.5em + .75rem + 2px)
  }

  textarea.form-control-sm {
    min-height: calc(1.5em + .5rem + 2px)
  }

  textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px)
  }

  .form-control-color {
    width: 3rem;
    height: auto;
    padding: .375rem
  }

  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
  }

  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: .5rem
  }

  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: .5rem
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
  }

  .input-group > .form-control, .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
  }

  .input-group > .form-control:focus, .input-group > .form-select:focus {
    z-index: 3
  }

  .input-group .btn {
    position: relative;
    z-index: 2
  }

  .input-group .btn:focus {
    z-index: 3
  }

  .input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: .125rem solid #ced4da;
    border-radius: .5rem
  }

  .input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
  }

  .input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
  }

  .input-group-lg > .form-select, .input-group-sm > .form-select {
    padding-right: 3rem
  }

  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -.125rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .form-floating {
    position: relative
  }

  .form-floating > .form-control, .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25
  }

  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: .125rem solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .form-floating > label {
      transition: none
    }
  }

  .form-floating > .form-control {
    padding: 1rem .75rem
  }

  .form-floating > .form-control::-moz-placeholder {
    color: transparent
  }

  .form-floating > .form-control::placeholder {
    color: transparent
  }

  .form-floating > .form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
  }

  .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
  }

  .form-floating > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
  }

  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
  }

  .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
    opacity: .65;
    transform: scale(.8) translateY(-.5rem) translateX(.15rem)
  }

  .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
    opacity: .65;
    transform: scale(.8) translateY(-.5rem) translateX(.15rem)
  }

  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: .65;
    transform: scale(.8) translateY(-.5rem) translateX(.15rem)
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
  }

  .input-group > .form-control, .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
  }

  .input-group > .form-control:focus, .input-group > .form-select:focus {
    z-index: 3
  }

  .input-group .btn {
    position: relative;
    z-index: 2
  }

  .input-group .btn:focus {
    z-index: 3
  }

  .input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: .125rem solid #ced4da;
    border-radius: .5rem
  }

  .input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
  }

  .input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
  }

  .input-group-lg > .form-select, .input-group-sm > .form-select {
    padding-right: 3rem
  }

  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -.125rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .calc-form .calc-form__btn-checkbox {
    position: relative;
    display: inline-flex
  }

  .calc-form .calc-form__btn-checkbox:not(:last-child) {
    margin-right: .625rem;
    margin-bottom: .625rem
  }

  @media (max-width: 575.98px) {
    .calc-form .calc-form__btn-checkbox:not(:last-child) {
      margin-right: .5rem;
      margin-bottom: .5rem
    }
  }

  .calc-form .calc-form__btn-checkbox label {
    padding: .5625rem .8125rem;
    font-size: .9375rem;
    font-weight: 400;
    background-color: #fff;
    color: #121212;
    border: .125rem solid #d0d0d0;
    letter-spacing: unset
  }

  .calc-form .calc-form__btn-checkbox label:active, .calc-form .calc-form__btn-checkbox label:focus {
    background-color: #fff;
    color: #121212;
    border: .125rem solid #d0d0d0
  }

  @media (min-width: 576px) {
    .calc-form .calc-form__btn-checkbox label:hover {
      background-color: #ff5c30;
      border-color: #ff5c30;
      color: #fff
    }
  }

  .calc-form .calc-form__btn-checkbox label:before {
    content: url("../images/icons/check_20.svg");
    position: absolute;
    left: .5625rem;
    width: 1.25rem;
    height: 1.25rem;
    top: 51%;
    transform: translateY(-50%);
    z-index: -1
  }

  .calc-form .calc-form__btn-checkbox input:checked ~ label:before {
    transition: .3s;
    z-index: 1
  }

  .calc-form .calc-form__btn-checkbox input:checked ~ label {
    background-color: #ff5c30;
    border-color: #ff5c30;
    padding-left: 2rem;
    color: #fff
  }

  @media (max-width: 767.98px) {
    .calc-form .form-floating {
      margin-top: 1rem
    }
  }

  .calc-form .calc-form__btn {
    font-size: 1.125rem;
    padding: 1.25rem 3rem
  }

  .calc-form .calc-form__title {
    margin-bottom: .5rem
  }

  .calc-form .calc-form__input-block {
    margin-top: 1.375rem
  }

  @media (max-width: 575.98px) {
    .calc-form .calc-form__input-block {
      margin-top: .625rem
    }
  }

  .calc-form .calc-form__input-block .title {
    margin-bottom: .5625rem;
    font-size: 1.125rem;
    font-weight: 500
  }

  .calc-form .calc-form__input-block .message {
    margin-top: .4375rem;
    font-size: .875rem;
    color: #ff3e3b;
    font-weight: 500
  }

  .calc-form .calc-form__input-block .btn-group {
    padding-left: .125rem
  }

  .calc-form .calc-form__input-block .btn-group .btn:not(:last-child) {
    margin-right: -2px
  }

  @media (max-width: 575.98px) {
    .calc-form .calc-form__input-block .btn-group .btn {
      padding: .75rem 2rem
    }
  }
  @media (max-width: 575.98px) {
    .calc-form .calc-form__input-block .btn-group {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      padding-bottom: .625rem
    }
  }

  .calc-form .input-calc {
    width: 10.75rem
  }

  .calc-form .input-calc .input-calc__minus, .calc-form .input-calc .input-calc__plus {
    padding: .625rem .9375rem;
    font-size: 1rem;
    font-weight: 500
  }

  .calc-form .input-calc .input-calc__minus:hover, .calc-form .input-calc .input-calc__plus:hover {
    z-index: 10
  }

  .calc-form .input-calc .input-calc__count {
    padding: .3125rem .75rem;
    font-size: .9375rem;
    text-align: center
  }

  .calc-form .input-calc .input-calc__count::-webkit-inner-spin-button, .calc-form .input-calc .input-calc__count::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
  }

  .calc-form .input-calc .input-calc__count[type=number] {
    -moz-appearance: textfield
  }

  .calc-form .calc-form__total {
    background-color: #f8f8f8;
    border-radius: 1rem;
    padding: 2rem
  }

  @media (max-width: 767.98px) {
    .calc-form .calc-form__total {
      padding: 1.5rem
    }
  }
  @media (max-width: 575.98px) {
    .calc-form .calc-form__total {
      padding: 1rem
    }
  }

  .calc-form .calc-form__total .row:not(:last-child) {
    margin-bottom: .875rem
  }

  @media (max-width: 575.98px) {
    .calc-form .calc-form__total .h6 {
      margin-bottom: 0
    }
  }

  .calc-form .calc-form__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 1.25rem
  }

  @media (max-width: 575.98px) {
    .calc-form .calc-form__sidebar {
      margin-top: 2rem
    }
  }

  .calc-form__slider .calc-form__slider-main .swiper-slide:hover {
    cursor: -webkit-grab;
    cursor: grab
  }

  .calc-form__slider .swiper-pagination {
    text-align: center;
    width: auto !important;
    left: auto !important;
    margin-top: 2rem
  }

  @media (max-width: 575.98px) {
    .calc-form__slider .swiper-pagination {
      margin-top: 1rem
    }
  }

  .calc-form__slider .swiper {
    width: 100%
  }

  .calc-form__slider .swiper-slide {
    text-align: center;
    font-size: 1.125rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center
  }

  .calc-form__slider .swiper-pagination-bullet {
    text-align: center;
    opacity: 1;
    padding: 1rem;
    border: .125rem solid transparent;
    border-radius: .5rem;
    max-width: 100%;
    height: auto
  }

  @media (max-width: 575.98px) {
    .calc-form__slider .swiper-pagination-bullet {
      padding: .5rem
    }
  }

  .calc-form__slider .swiper-pagination-bullet:hover {
    cursor: pointer;
    filter: brightness(1.1)
  }

  .calc-form__slider .swiper-pagination-bullet-active {
    border-color: #d0d0d0
  }

  .calc-form__slider .swiper-pagination-bullet-active:hover {
    cursor: pointer;
    filter: unset
  }

  .text-content {
    font-size: 1.0625rem;
    line-height: 1.6
  }

  @media (max-width: 575.98px) {
    .text-content {
      font-size: 1rem;
      line-height: 1.4
    }
  }

  .text-content .blockquote, .text-content blockquote {
    padding: 1.25rem 1.5rem;
    background-color: #fff9eb;
    border: .0625rem solid #ffe3a6;
    border-radius: .5rem;
    font-size: 1rem;
    line-height: 1.5
  }

  @media (max-width: 575.98px) {
    .text-content .blockquote, .text-content blockquote {
      padding: .625rem 1rem;
      font-size: .9375rem;
      line-height: 1.4
    }
  }

  .text-content .blockquote:before, .text-content blockquote:before {
    content: url("../images/icons/alert-triangle.svg");
    display: block
  }

  .text-content img {
    max-width: 100%;
    height: auto
  }

  .text-content img + p {
    margin-top: 1.5rem
  }

  .text-content .h1, .text-content .h2, .text-content .h3, .text-content h1, .text-content h2, .text-content h3 {
    margin-bottom: 1rem
  }

  .text-content .h1:not(:first-child), .text-content .h2:not(:first-child), .text-content .h3:not(:first-child), .text-content h1:not(:first-child), .text-content h2:not(:first-child), .text-content h3:not(:first-child) {
    margin-top: 2rem
  }

  .text-content .h4, .text-content .h5, .text-content .h6, .text-content h4, .text-content h5, .text-content h6 {
    margin-bottom: .5rem
  }

  .text-content .h4:not(:first-child), .text-content .h5:not(:first-child), .text-content .h6:not(:first-child), .text-content h4:not(:first-child), .text-content h5:not(:first-child), .text-content h6:not(:first-child) {
    margin-top: 2rem
  }

  .text-content ol, .text-content ul {
    padding: 0;
    list-style: none
  }

  .text-content ol li, .text-content ul li {
    padding-left: 1.5rem;
    position: relative
  }

  .text-content ol li:not(:last-child), .text-content ul li:not(:last-child) {
    margin-bottom: .5rem
  }

  .text-content ol li::before, .text-content ul li::before {
    content: "->";
    font-weight: 500;
    color: #ff7c24;
    line-height: 1.7;
    position: absolute;
    left: 0
  }

  @media (max-width: 575.98px) {
    .text-content ol li::before, .text-content ul li::before {
      line-height: 1.4
    }
  }

  .about-page .text-content .h1, .about-page .text-content .h2, .about-page .text-content .h3, .about-page .text-content h1, .about-page .text-content h2, .about-page .text-content h3 {
    margin-bottom: 1.5rem
  }

  .about-page .text-content .h1:not(:first-child), .about-page .text-content .h2:not(:first-child), .about-page .text-content .h3:not(:first-child), .about-page .text-content h1:not(:first-child), .about-page .text-content h2:not(:first-child), .about-page .text-content h3:not(:first-child) {
    margin-top: 4.5rem
  }

  @media (max-width: 767.98px) {
    .about-page .text-content .h1:not(:first-child), .about-page .text-content .h2:not(:first-child), .about-page .text-content .h3:not(:first-child), .about-page .text-content h1:not(:first-child), .about-page .text-content h2:not(:first-child), .about-page .text-content h3:not(:first-child) {
      margin-top: 2rem
    }
  }

  .about-page .text-content .h4, .about-page .text-content .h5, .about-page .text-content .h6, .about-page .text-content h4, .about-page .text-content h5, .about-page .text-content h6 {
    margin-bottom: 1rem
  }

  .about-page .text-content .h4:not(:first-child), .about-page .text-content .h5:not(:first-child), .about-page .text-content .h6:not(:first-child), .about-page .text-content h4:not(:first-child), .about-page .text-content h5:not(:first-child), .about-page .text-content h6:not(:first-child) {
    margin-top: 4rem
  }

  @media (max-width: 767.98px) {
    .about-page .text-content .h4:not(:first-child), .about-page .text-content .h5:not(:first-child), .about-page .text-content .h6:not(:first-child), .about-page .text-content h4:not(:first-child), .about-page .text-content h5:not(:first-child), .about-page .text-content h6:not(:first-child) {
      margin-top: 1.5rem
    }
  }

  .manager-form {
    background-color: #f8f8f8;
    border-radius: 1rem;
    padding: 1.5rem
  }

  @media (max-width: 767.98px) {
    .manager-form {
      margin-top: 1.5rem
    }
  }

  .manager-form .manager-form__header {
    margin-bottom: 1.5rem
  }

  @media (max-width: 575.98px) {
    .manager-form .manager-form__header {
      margin-bottom: 1rem
    }
  }

  .manager-form .manager-form__header .manager {
    margin-left: 1.25rem
  }

  .manager-form .manager-form__header .manager .manager-name {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.4;
    color: #121212
  }

  .manager-form .manager-form__header .manager .manager-description {
    margin-top: .25rem;
    font-size: .875rem;
    line-height: 1.4;
    color: #717171
  }

  .manager-form .manager-form__header .manager .manager-phone {
    margin-top: .5rem;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.4;
    color: #121212
  }

  .manager-form .manager-form__form .form-floating {
    margin-bottom: 1rem
  }

  .swiper-button-disabled {
    display: none
  }

  .portfolio-carousel__wrap {
    position: relative
  }

  @media (max-width: 575.98px) {
    .portfolio-carousel__wrap .portfolio-carousel .swiper-slide {
      width: 80%
    }
  }

  .portfolio-carousel__wrap .portfolio-carousel-button {
    position: absolute;
    background-color: #fff;
    border-radius: 6.1875rem;
    padding: .5rem;
    border: .125rem solid #e8e8e8;
    width: 3rem;
    height: 3rem;
    text-align: center;
    z-index: 99;
    top: calc(50% - 24px);
    transition: .15s
  }

  @media (prefers-reduced-motion: reduce) {
    .portfolio-carousel__wrap .portfolio-carousel-button {
      transition: none
    }
  }

  .portfolio-carousel__wrap .portfolio-carousel-button svg {
    transition: .15s
  }

  @media (prefers-reduced-motion: reduce) {
    .portfolio-carousel__wrap .portfolio-carousel-button svg {
      transition: none
    }
  }

  .portfolio-carousel__wrap .portfolio-carousel-button:hover {
    border-color: #ff7c24
  }

  .portfolio-carousel__wrap .portfolio-carousel-button:hover svg {
    fill: #ff7c24
  }

  .portfolio-carousel__wrap .portfolio-carousel-button svg {
    top: 0;
    fill: #717171
  }

  .portfolio-carousel__wrap .portfolio-carousel-button.portfolio-carousel-button-next {
    right: -24px
  }

  @media (max-width: 1199.98px) {
    .portfolio-carousel__wrap .portfolio-carousel-button.portfolio-carousel-button-next {
      right: -10px
    }
  }

  .portfolio-carousel__wrap .portfolio-carousel-button.portfolio-carousel-button-prev {
    left: -24px
  }

  @media (max-width: 1199.98px) {
    .portfolio-carousel__wrap .portfolio-carousel-button.portfolio-carousel-button-prev {
      left: -10px
    }
  }

  .fancybox-image {
    display: inline-block;
    border-radius: 1rem
  }

  .fancybox-image:hover {
    cursor: zoom-in;
    background: #ff7c24
  }

  .fancybox-image:hover .fancybox-image__img {
    opacity: .6
  }

  .fancybox-image .fancybox-image__img {
    border-radius: 1rem;
    transition: .15s
  }

  @media (prefers-reduced-motion: reduce) {
    .fancybox-image .fancybox-image__img {
      transition: none
    }
  }

  .accordion-button {
    font-size: 1.125rem;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.25rem 2rem;
    color: #121212;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
  }

  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none
    }
  }

  .accordion-button:hover {
    color: #ff5c30
  }

  .accordion-button:not(.collapsed) {
    color: #ff5c30;
    background-color: #f8f8f8
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff5c30'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg)
  }

  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
      transition: none
    }
  }

  .accordion-button:hover {
    z-index: 2
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0
  }

  .accordion-header {
    margin-bottom: 0
  }

  .accordion-item {
    background-color: #fff;
    border: .125rem solid rgba(0, 0, 0, .125)
  }

  .accordion-item:first-of-type {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
  }

  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
  }

  .accordion-item:not(:first-of-type) {
    border-top: 0
  }

  .accordion-item:last-of-type {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
  }

  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
  }

  .accordion-body {
    padding: 1rem 1.25rem
  }

  .accordion-flush .accordion-collapse {
    border-width: 0
  }

  .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
  }

  .accordion-flush .accordion-item:first-child {
    border-top: 0
  }

  .accordion-flush .accordion-item:last-child {
    border-bottom: 0
  }

  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0
  }

  .latest-from-blog .latest-from-blog__item {
    margin-top: 1.5rem;
    display: block;
    text-decoration: none;
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 1.4;
    color: #121212;
    transition: .15s
  }

  @media (prefers-reduced-motion: reduce) {
    .latest-from-blog .latest-from-blog__item {
      transition: none
    }
  }
  @media (max-width: 767.98px) {
    .latest-from-blog .latest-from-blog__item {
      margin-top: 1rem
    }
  }
  @media (max-width: 575.98px) {
    .latest-from-blog .latest-from-blog__item {
      padding-bottom: .5rem
    }
  }

  .latest-from-blog .latest-from-blog__item:hover {
    color: #ff5c30
  }

  @media (min-width: 768px) {
    .latest-from-blog .latest-from-blog__item:hover {
      transform: translateY(-2px)
    }
  }

  .latest-from-blog .latest-from-blog__item:hover img {
    filter: brightness(1.1)
  }

  .latest-from-blog .latest-from-blog__item img {
    transition: .15s;
    border-radius: 1rem
  }

  @media (prefers-reduced-motion: reduce) {
    .latest-from-blog .latest-from-blog__item img {
      transition: none
    }
  }

  .latest-from-blog .latest-from-blog__item .latest-from-blog__title {
    margin-top: 1.25rem;
    display: block
  }

  @media (max-width: 767.98px) {
    .latest-from-blog .latest-from-blog__item .latest-from-blog__title {
      margin-top: .75rem
    }
  }

  table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #d0d0d0
  }

  @media (max-width: 575.98px) {
    table {
      font-size: .9375rem;
      line-height: 1.3
    }
  }

  table > :not(caption) > * > * {
    padding: .625rem 1rem;
    background-color: var(--bs-table-bg);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
  }

  @media (max-width: 575.98px) {
    table > :not(caption) > * > * {
      padding: .5rem .75rem
    }
  }

  table > tbody {
    vertical-align: inherit
  }

  table > thead {
    vertical-align: bottom
  }

  table > :not(caption) > * {
    border-width: 1px 0
  }

  table > :not(caption) > * > * {
    border-width: 0 1px
  }

  .caption-top {
    caption-side: top
  }

  .table-sm > :not(caption) > * > * {
    padding: .5rem .75rem
  }

  .table-bordered > :not(caption) > * {
    border-width: 1px 0
  }

  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px
  }

  .table-borderless > :not(caption) > * > * {
    border-bottom-width: 0
  }

  .table-borderless > :not(:first-child) {
    border-top-width: 0
  }

  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
  }

  .table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
  }

  .table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
  }

  .table-primary {
    --bs-table-bg: #cfe2ff;
    --bs-table-striped-bg: #c5d7f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bacbe6
  }

  .table-secondary {
    --bs-table-bg: #e2e3e5;
    --bs-table-striped-bg: #d7d8da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cbccce;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d1d2d4;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #cbccce
  }

  .table-success {
    --bs-table-bg: #d1e7dd;
    --bs-table-striped-bg: #c7dbd2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bcd0c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1d6cc;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bcd0c7
  }

  .table-info {
    --bs-table-bg: #cff4fc;
    --bs-table-striped-bg: #c5e8ef;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #badce3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfe2e9;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #badce3
  }

  .table-warning {
    --bs-table-bg: #fff3cd;
    --bs-table-striped-bg: #f2e7c3;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dbb9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece1be;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6dbb9
  }

  .table-danger {
    --bs-table-bg: #f8d7da;
    --bs-table-striped-bg: #eccccf;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfc2c4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5c7ca;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfc2c4
  }

  .table-light {
    --bs-table-bg: #f8f9fa;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfe0e1
  }

  .table-dark {
    --bs-table-bg: #212529;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #373b3e
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  @media (max-width: 575.98px) {
    .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch
    }
  }
  @media (max-width: 767.98px) {
    .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch
    }
  }
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch
    }
  }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch
    }
  }
  @media (max-width: 1399.98px) {
    .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch
    }
  }

  .portfolio-tags .list-inline-item:not(:last-child) {
    margin-right: .75rem;
    margin-bottom: .75rem
  }

  .portfolio-tags .portfolio-tags__item {
    font-size: 1rem;
    font-weight: 400;
    padding: .8125rem 1.5rem;
    border-radius: .5rem;
    display: block;
    text-decoration: none
  }

  .portfolio-tags .portfolio-tags__item:hover {
    background-color: #fff;
    color: #121212;
    border-color: #ff5c30
  }

  .portfolio-tags .portfolio-tags__item.active {
    background-color: #ff5c30;
    color: #fff;
    border-color: #ff5c30
  }

  .portfolio-grid .portfolio-grid__item {
    display: block;
    margin-top: 1.5rem;
    text-decoration: none;
    transition: .15s
  }

  @media (prefers-reduced-motion: reduce) {
    .portfolio-grid .portfolio-grid__item {
      transition: none
    }
  }
  @media (max-width: 575.98px) {
    .portfolio-grid .portfolio-grid__item {
      margin-top: .75rem
    }
  }

  .portfolio-grid .portfolio-grid__item:hover {
    cursor: zoom-in
  }

  .portfolio-grid .portfolio-grid__item:hover img {
    filter: brightness(1.1)
  }

  .portfolio-grid .portfolio-grid__item img {
    border-radius: 1rem;
    transition: .15s
  }

  @media (prefers-reduced-motion: reduce) {
    .portfolio-grid .portfolio-grid__item img {
      transition: none
    }
  }

  .clients-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1.5rem;
    place-items: stretch;
    place-content: center
  }

  @media (max-width: 767.98px) {
    .clients-grid {
      grid-template-columns: repeat(4, 1fr)
    }
  }
  @media (max-width: 575.98px) {
    .clients-grid {
      grid-template-columns: repeat(2, 1fr)
    }
  }

  .clients-grid .clients-grid__item {
    background-color: #f8f8f8;
    border-radius: .5rem;
    min-height: 4.5rem;
    padding: .5rem;
    text-align: center;
    place-self: stretch;
    display: flex;
    align-items: center;
    width: 100%;
    transition: .3s
  }

  .contacts-page .contacts-page__sidebar-block {
    margin-bottom: 4rem
  }

  @media (max-width: 767.98px) {
    .contacts-page .contacts-page__sidebar-block {
      margin-bottom: 2rem
    }
  }

  .contacts-page .contacts-page__sidebar-block .contacts-page__big li:not(:last-child) {
    margin-bottom: 1.25rem
  }

  @media (max-width: 767.98px) {
    .contacts-page .contacts-page__sidebar-block .contacts-page__big
    li:not(:last-child) {
      margin-bottom: .75rem
    }
  }

  .contacts-page .contacts-page__sidebar-block .contacts-page__big a {
    text-decoration: none;
    color: #121212;
    line-height: 1.4;
    border-bottom: .0625rem solid rgba(18, 18, 18, .2);
    font-size: 1.5rem;
    font-weight: 600
  }

  .contacts-page .contacts-page__sidebar-block .contacts-page__big a:hover {
    color: #ff7c24;
    border-color: rgba(255, 124, 36, .5)
  }

  .contacts-page .contacts-page__sidebar-block .contacts-page__small li:not(:last-child) {
    margin-bottom: .75rem
  }

  .contacts-page .contacts-page__sidebar-block .contacts-page__small a {
    text-decoration: none;
    color: #121212;
    line-height: 1.4;
    font-size: 1.125rem;
    font-weight: 500
  }

  .contacts-page .contacts-page__sidebar-block .contacts-page__small a span {
    border-bottom: .0625rem solid rgba(18, 18, 18, .2);
    margin-left: .75rem
  }

  .contacts-page .contacts-page__sidebar-block .contacts-page__small a:hover {
    color: #ff7c24
  }

  .contacts-page .contacts-page__sidebar-block .contacts-page__small a:hover span {
    border-color: rgba(255, 124, 36, .5)
  }

  .contacts-page .contacts-page__address {
    margin-bottom: 1rem
  }

  @media (max-width: 575.98px) {
    .contacts-page .contacts-page__address {
      margin-bottom: .75rem
    }
  }

  .contacts-page .contacts-page__time {
    margin-bottom: 1.5rem
  }

  @media (max-width: 575.98px) {
    .contacts-page .contacts-page__time {
      margin-bottom: 1.5rem
    }
  }

  .contacts-page .contacts-page__map {
    margin-top: 4rem;
    height: 25rem;
    background-color: #f8f8f8;
    border-radius: 1rem;
    overflow: hidden
  }

  @media (max-width: 767.98px) {
    .contacts-page .contacts-page__map {
      margin-top: 2rem;
      height: 21.25rem;
      margin-bottom: 1rem
    }
  }

  .prices-page .prices-page__data {
    margin-top: 7.5rem
  }

  @media (max-width: 767.98px) {
    .prices-page .prices-page__data {
      margin-top: 4.5rem
    }
  }
  @media (max-width: 575.98px) {
    .prices-page .prices-page__data {
      margin-top: 2rem
    }
  }

  .prices-page .prices-page__data .prices-page__anchors {
    margin-top: 1rem
  }

  .prices-page .prices-page__data .prices-page__anchors .list-inline-item:not(:last-child) {
    margin-right: 1.5rem
  }

  .prices-page .prices-page__data .prices-page__anchors a {
    font-size: 1.125rem
  }

  .prices-page__data-block {
    margin-top: 7.5rem
  }

  .prices-page__data-block:last-child {
    margin-bottom: 7.5rem
  }

  @media (max-width: 767.98px) {
    .prices-page__data-block {
      margin-top: 4.5rem
    }
    .prices-page__data-block:last-child {
      margin-bottom: 4.5rem
    }
  }
  @media (max-width: 575.98px) {
    .prices-page__data-block {
      margin-top: 2.5rem
    }
    .prices-page__data-block:last-child {
      margin-bottom: 2.5rem
    }
  }

  .prices-page__data-block .prices-page__data-block-item {
    background-color: #e8e8e8;
    border-radius: 1rem;
    padding: 2rem
  }

  @media (max-width: 767.98px) {
    .prices-page__data-block .prices-page__data-block-item {
      padding: 1.5rem;
      margin-bottom: 1rem
    }
  }
  @media (max-width: 575.98px) {
    .prices-page__data-block .prices-page__data-block-item {
      padding: 1rem;
      margin-bottom: 1rem
    }
  }

  .prices-page__data-block .prices-page__data-block-item .title {
    margin-bottom: 2rem
  }

  .prices-page__data-block .prices-page__data-block-item .image {
    margin: 0
  }

  .prices-page__data-block .prices-page__data-block-item .prices {
    margin-top: 1.5rem;
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.7
  }

  @media (max-width: 991.98px) {
    .prices-page__data-block .prices-page__data-block-item .prices {
      font-size: 1rem
    }
  }

  .prices-page__data-block .prices-page__data-block-item .prices-btn {
    margin-top: 1.5rem;
    background: unset;
    background-color: #fff;
    color: #121212;
    font-size: 1.125rem;
    font-weight: 500
  }

  .prices-page__data-block .prices-page__data-block-item .prices-btn:hover {
    background-color: #ff5c30;
    color: #fff
  }

  @media (max-width: 991.98px) {
    .text-page__sidebar {
      margin-bottom: 2rem
    }
  }
  @media (max-width: 767.98px) {
    .text-page__sidebar {
      margin-bottom: 1.5rem
    }
  }

  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: .25rem
  }

  .list-group-numbered {
    list-style-type: none;
    counter-reset: section
  }

  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section
  }

  .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
  }

  .list-group-item-action:focus, .list-group-item-action:hover {
    z-index: 1;
    color: #ff5c30;
    text-decoration: none;
    background-color: #f8f8f8;
    border-color: #e8e8e8
  }

  .list-group-item-action:active {
    color: #fff;
    background-color: #ff5c30
  }

  .list-group-item {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border: .125rem solid rgba(0, 0, 0, .125)
  }

  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
  }

  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
  }

  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff
  }

  .list-group-item:hover {
    z-index: 4
  }

  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ff5c30;
    border-color: #ff5c30
  }

  .list-group-item + .list-group-item {
    border-top-width: 0
  }

  .list-group-item + .list-group-item.active {
    margin-top: -.125rem;
    border-top-width: .125rem
  }

  .list-group-horizontal {
    flex-direction: row
  }

  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal > .list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: .125rem;
    border-left-width: 0
  }

  .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -.125rem;
    border-left-width: .125rem
  }

  @media (min-width: 576px) {
    .list-group-horizontal-sm {
      flex-direction: row
    }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0
    }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: .25rem;
      border-bottom-left-radius: 0
    }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: .125rem;
      border-left-width: 0
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -.125rem;
      border-left-width: .125rem
    }
  }
  @media (min-width: 768px) {
    .list-group-horizontal-md {
      flex-direction: row
    }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0
    }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: .25rem;
      border-bottom-left-radius: 0
    }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: .125rem;
      border-left-width: 0
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -.125rem;
      border-left-width: .125rem
    }
  }
  @media (min-width: 992px) {
    .list-group-horizontal-lg {
      flex-direction: row
    }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0
    }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: .25rem;
      border-bottom-left-radius: 0
    }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: .125rem;
      border-left-width: 0
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -.125rem;
      border-left-width: .125rem
    }
  }
  @media (min-width: 1200px) {
    .list-group-horizontal-xl {
      flex-direction: row
    }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0
    }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: .25rem;
      border-bottom-left-radius: 0
    }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: .125rem;
      border-left-width: 0
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -.125rem;
      border-left-width: .125rem
    }
  }
  @media (min-width: 1400px) {
    .list-group-horizontal-xxl {
      flex-direction: row
    }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0
    }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: .25rem;
      border-bottom-left-radius: 0
    }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: .125rem;
      border-left-width: 0
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
      margin-left: -.125rem;
      border-left-width: .125rem
    }
  }

  .list-group-flush {
    border-radius: 0
  }

  .list-group-flush > .list-group-item {
    border-width: 0 0 .125rem
  }

  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0
  }

  .list-group-item-primary {
    color: #084298;
    background-color: #cfe2ff
  }

  .list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
    color: #084298;
    background-color: #bacbe6
  }

  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298
  }

  .list-group-item-secondary {
    color: #41464b;
    background-color: #e2e3e5
  }

  .list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
    color: #41464b;
    background-color: #cbccce
  }

  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b
  }

  .list-group-item-success {
    color: #0f5132;
    background-color: #d1e7dd
  }

  .list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
    color: #0f5132;
    background-color: #bcd0c7
  }

  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132
  }

  .list-group-item-info {
    color: #055160;
    background-color: #cff4fc
  }

  .list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
    color: #055160;
    background-color: #badce3
  }

  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160
  }

  .list-group-item-warning {
    color: #664d03;
    background-color: #fff3cd
  }

  .list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
    color: #664d03;
    background-color: #e6dbb9
  }

  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03
  }

  .list-group-item-danger {
    color: #842029;
    background-color: #f8d7da
  }

  .list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
    color: #842029;
    background-color: #dfc2c4
  }

  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029
  }

  .list-group-item-light {
    color: #636464;
    background-color: #fefefe
  }

  .list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
    color: #636464;
    background-color: #e5e5e5
  }

  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464
  }

  .list-group-item-dark {
    color: #141619;
    background-color: #d3d3d4
  }

  .list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
    color: #141619;
    background-color: #bebebf
  }

  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619
  }

  .reviews-page .reviews-page__item {
    display: block;
    text-decoration: none;
    color: #121212;
    border: .125rem solid #e8e8e8;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 1.5rem
  }

  .reviews-page .reviews-page__item:hover {
    border-color: #ff7c24
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
  }

  .nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none
    }
  }

  .nav-link:focus, .nav-link:hover {
    color: #0a58ca
  }

  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
  }

  .nav-tabs {
    border-bottom: 1px solid #dee2e6
  }

  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
  }

  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate
  }

  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
  }

  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .nav-pills .nav-item:not(:last-child) {
    margin-right: .75rem
  }

  .nav-pills .nav-link {
    font-size: 1rem;
    background: 0 0;
    border: .125rem solid #d0d0d0;
    color: #121212;
    border-radius: .5rem
  }

  .nav-pills .nav-link:hover {
    border-color: #ff5c30
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    border-color: #ff5c30;
    font-size: 1rem;
    color: #fff;
    background-color: #ff5c30
  }

  .nav-fill .nav-item, .nav-fill > .nav-link {
    flex: 1 1 auto;
    text-align: center
  }

  .nav-justified .nav-item, .nav-justified > .nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
  }

  .nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
    width: 100%
  }

  .tab-content > .tab-pane {
    display: none
  }

  .tab-content > .active {
    display: block
  }

  ::-moz-placeholder {
    color: rgba(18, 18, 18, .5)
  }

  ::placeholder {
    color: rgba(18, 18, 18, .5)
  }

  input, textarea {
    outline: 0
  }

  input:focus:required:invalid, textarea:focus:required:invalid {
    border-color: red
  }

  input:required:valid, textarea:required:valid {
    border-color: green
  }

  .focus, :focus {
    outline: 0
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #121212;
    font-family: Inter, sans-serif
  }

  .h1, h1 {
    font-weight: 900;
    font-size: 4rem;
    line-height: 1.3
  }

  @media (max-width: 991.98px) {
    .h1, h1 {
      font-size: 2.5rem
    }
  }
  @media (max-width: 767.98px) {
    .h1, h1 {
      font-size: 2rem
    }
  }

  .h2, h2 {
    font-weight: 900;
    font-size: 3rem;
    line-height: 1.3
  }

  @media (max-width: 991.98px) {
    .h2, h2 {
      font-size: 2.25rem
    }
  }
  @media (max-width: 767.98px) {
    .h2, h2 {
      font-size: 1.75rem
    }
  }

  .h3, h3 {
    font-weight: 900;
    font-size: 2.25rem;
    line-height: 1.3
  }

  @media (max-width: 991.98px) {
    .h3, h3 {
      font-size: 2rem
    }
  }
  @media (max-width: 767.98px) {
    .h3, h3 {
      font-size: 1.5rem
    }
  }

  .h4, h4 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.3
  }

  @media (max-width: 991.98px) {
    .h4, h4 {
      font-size: 1.375rem
    }
  }
  @media (max-width: 767.98px) {
    .h4, h4 {
      font-size: 1.25rem
    }
  }

  .h5, h5 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.3
  }

  @media (max-width: 991.98px) {
    .h5, h5 {
      font-size: 1.1875rem
    }
  }
  @media (max-width: 767.98px) {
    .h5, h5 {
      font-size: 1.125rem
    }
  }

  .h6, h6 {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.3
  }

  @media (max-width: 991.98px) {
    .h6, h6 {
      font-size: 1.0625rem
    }
  }
  @media (max-width: 767.98px) {
    .h6, h6 {
      font-size: 1rem
    }
  }

  a {
    color: #1a66ff;
    transition: .15s
  }

  @media (prefers-reduced-motion: reduce) {
    a {
      transition: none
    }
  }

  a:hover {
    color: #ff7c24
  }

  .unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none
  }

  .rounded {
    border-radius: 4px
  }

  .text-red {
    color: #ff3e3b !important
  }

  .text-orange {
    color: #ff7c24 !important
  }

  .text-orange-alt {
    color: #ff5c30 !important
  }

  .mb-56 {
    margin-bottom: 3.5rem
  }

  @media (max-width: 991.98px) {
    .mb-56 {
      margin-bottom: 3rem
    }
  }
  @media (max-width: 767.98px) {
    .mb-56 {
      margin-bottom: 2rem
    }
  }
  @media (max-width: 575.98px) {
    .mb-56 {
      margin-bottom: 1rem
    }
  }

  .mb-40 {
    margin-bottom: 2.5rem
  }

  @media (max-width: 991.98px) {
    .mb-40 {
      margin-bottom: 2rem
    }
  }
  @media (max-width: 767.98px) {
    .mb-40 {
      margin-bottom: 1.5rem
    }
  }
  @media (max-width: 575.98px) {
    .mb-40 {
      margin-bottom: 1rem
    }
  }

  .mt-56 {
    margin-top: 3.5rem
  }

  @media (max-width: 991.98px) {
    .mt-56 {
      margin-top: 3rem
    }
  }
  @media (max-width: 767.98px) {
    .mt-56 {
      margin-top: 2rem
    }
  }
  @media (max-width: 575.98px) {
    .mt-56 {
      margin-top: 1rem
    }
  }

  .mb-24 {
    margin-bottom: 1.5rem
  }

  @media (max-width: 767.98px) {
    .mb-24 {
      margin-bottom: 1.25rem
    }
  }
  @media (max-width: 575.98px) {
    .mb-24 {
      margin-bottom: 1rem
    }
  }

  .mt-24 {
    margin-top: 1.5rem
  }

  @media (max-width: 767.98px) {
    .mt-24 {
      margin-top: 1.5rem
    }
  }
  @media (max-width: 575.98px) {
    .mt-24 {
      margin-top: 1rem
    }
  }

  .mb-48 {
    margin-bottom: 3rem
  }

  @media (max-width: 991.98px) {
    .mb-48 {
      margin-bottom: 2.5rem
    }
  }
  @media (max-width: 767.98px) {
    .mb-48 {
      margin-bottom: 2rem
    }
  }
  @media (max-width: 575.98px) {
    .mb-48 {
      margin-bottom: 1rem
    }
  }

  .rounded-16 {
    border-radius: 1rem
  }

  .section {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem
  }

  .section + .section {
    padding-top: 0
  }

  @media (max-width: 991.98px) {
    .section {
      padding-top: 5rem;
      padding-bottom: 5rem
    }
  }
  @media (max-width: 767.98px) {
    .section {
      padding-top: 4rem;
      padding-bottom: 4rem
    }
  }
  @media (max-width: 575.98px) {
    .section {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem
    }
  }

  .text-muted-60 {
    color: #717171 !important
  }

  .bg-primary-10 {
    background-color: #e8e8e8
  }

  .bg-primary-05 {
    background-color: #f8f8f8
  }
}
