#calc-public-transport .calc-corp-transport-wrap{
  .swiper-wrapper {
    max-height: 204px;

    .swiper-slide {
      flex-shrink: 0;
      max-width: 100%;
      height: 204px;

      img {
        max-height: 204px;
        max-width: 100%;
      }
    }
  }
  .calc-form__slider-pagination{
    max-width: 100%;
    display: flex;
    justify-content: stretch;
    margin: 32px 0;

    .pagination-bullet{
      border: 2px solid transparent;
      padding: 15px;
      border-radius: 10px;

      &.active{
        border-color: #d0d0d0;
      }

      img{
        max-width: 100%;
        max-height: 64px;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 576px) {
    .row{
      max-width: 100vw;
      &>*{
        height: 100%;
      }
    }
  }

}